import { useCallback, useContext } from "react";
import axios from "axios";

import AuthContext from "common/store/AuthContext";
import { generateErrorMsg, getServerUrl } from "utils/commonTools";
import { GetBasicResponse } from "common/types/responses/BasicResponse";
import { LastUpdatedInfo } from "common/types/LastUpdatedInfo";
import { StaffMasterData, StaffMasterInfo } from "../types/StaffMasterInfo";
import { NameID } from "common/types/NameID";

export const useStaffMaintenance = () => {
  const authCtxt = useContext(AuthContext);
  const { token } = authCtxt;

  //--------------------------------------
  // 従業員管理用のメタデータを取得する
  //--------------------------------------
  const getMasterInfo = useCallback(
    async (
      parkId: number
    ): Promise<{ succeeded: boolean; msg: string; data?: StaffMasterInfo }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };

      try {
        const { data } = await axios.get(getServerUrl() + `maintenance/staffs/${parkId}`, config);
        const metaData = data as GetBasicResponse<StaffMasterInfo>;
        if (!metaData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }
        return {
          succeeded: true,
          msg: "",
          data: metaData.data,
        };
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    [token]
  );

  //--------------------------------------
  // 保存処理の実行
  //--------------------------------------
  const updateMasterData = useCallback(
    async (
      parkId: number,
      data: StaffMasterData
    ): Promise<{
      succeeded: boolean;
      data?: StaffMasterData;
      msg: string;
      lastUpdated?: LastUpdatedInfo;
    }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };
      try {
        const url = `${getServerUrl()}maintenance/staffs/${parkId}`;
        const result = await axios.post(url, data, config);

        const responseData = result.data as GetBasicResponse<StaffMasterData>;
        if (!responseData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }
        authCtxt.updateToken(responseData.token, new Date(responseData.expiresAt));

        return { succeeded: true, data: responseData.data, msg: "" };
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    [authCtxt, token]
  );

  const createEmptyStaff = (posts: NameID[]): StaffMasterData => {
    // 正の値で一番大きいものをデフォルトにする (＝一般社員になる)
    const post = posts.sort((x) => x.id).at(-1) ?? { id: -1, name: "" };
    return {
      employeeID: -1,
      employeeName: "",
      postID: post.id,
      postName: post.name,
      displayOrder: 1,
      isWorking: true,
    } as StaffMasterData;
  };

  return { getMasterInfo, updateMasterData, createEmptyStaff };
};
