import { memo } from "react";
import styled from "styled-components";
import { ILLinkContainer } from "../atoms/ILLinkContainer";

type Props = {
  iconClassName: string;
  title: string;
  to: string;
  search?: string;
};

export const SideMenuItem = memo((props: Props) => {
  const { iconClassName, title, to, search } = props;
  return (
    <SLinkContainer className="nav-item" to={{ pathname: to, search }}>
      <div className="nav-link text-primary text-truncate" title={title}>
        <SIcon className={iconClassName}></SIcon>
        <span>{title}</span>
      </div>
    </SLinkContainer>
  );
});

const SLinkContainer = styled(ILLinkContainer)`
  display: flex;
  align-items: center;
  padding: 0.4rem 1rem !important;
  &.nav-link.active {
    background-color: ${(props) => props.theme.color.menuSelectedBg};
    color: ${(props) => props.theme.color.menuSelectedFg} !important;
    &.disabled {
      color: green;
    }
  }
  &.disabled {
    color: ${(props) => props.theme.color.disabled} !important;
  }
`;

const SIcon = styled.i`
  margin-right: 0.2rem;
  width: 20px;
`;
