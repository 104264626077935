import { useCallback, useContext } from "react";
import axios from "axios";

import AuthContext from "common/store/AuthContext";
import {
  generateErrorMsg,
  genApiDatePath,
  getServerUrl,
  generateSaveResult,
} from "utils/commonTools";
import {
  GetBasicResponse,
  PostBasicResponse,
  PostResponse,
} from "common/types/responses/BasicResponse";
import { LastUpdatedInfo } from "common/types/LastUpdatedInfo";
import { WorkReports } from "pages/workreport/types/WorkReportData";
import { WorkReportMeta } from "../types/WorkReportMeta";
import { WorkReportType } from "../types/WorkReportType";

/////////////////////////////////////////////////
// 巡回作業日報のデータをサーバーから取得する
/////////////////////////////////////////////////
export const useWorkReport = () => {
  const authCtxt = useContext(AuthContext);
  const { token } = authCtxt;

  //--------------------------------------
  // メタデータをサーバーから取得する
  //--------------------------------------
  const loadMetaData = useCallback(
    async (
      parkId: number,
      target?: WorkReportType
    ): Promise<{ succeeded: boolean; msg: string; data?: WorkReportMeta }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };

      try {
        const { data } = await axios.get(
          getServerUrl() + `workReport/meta/${parkId}${target !== undefined ? "/" + target : ""}`,
          config
        );
        const metaData = data as GetBasicResponse<WorkReportMeta>;
        if (!metaData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }
        return {
          succeeded: true,
          msg: "",
          data: { ...metaData.data, periods: [...metaData.data.periods.filter((x) => x.id)] },
        };
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //--------------------------------------
  // 日報データをサーバーから取得する
  //--------------------------------------
  const getReportData = useCallback(
    async (
      parkId: number,
      date: Date,
      target?: WorkReportType
    ): Promise<{
      succeeded: boolean;
      msg: string;
      data?: WorkReports;
      lastUpdated?: LastUpdatedInfo;
    }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };
      try {
        // 日報データの取得
        const url =
          getServerUrl() +
          `workReport/report/${parkId}/${genApiDatePath(date)}${
            target !== undefined ? "/" + target : ""
          }`;

        const { data } = await axios.get(url, config);
        const reportData = data as PostBasicResponse<WorkReports>;
        if (!reportData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }

        authCtxt.updateToken(reportData.token, new Date(reportData.expiresAt));

        return {
          succeeded: true,
          msg: "",
          data: reportData.data,
          lastUpdated: reportData.lastUpdated,
        };
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //--------------------------------------
  // 保存処理の実行
  //--------------------------------------
  const saveReportData = useCallback(
    async (
      parkId: number,
      date: Date,
      reports: WorkReports,
      title: string,
      target?: WorkReportType
    ): Promise<{ succeeded: boolean; msg: string; lastUpdated?: LastUpdatedInfo }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };
      try {
        const url =
          getServerUrl() +
          `workReport/report/${parkId}/${genApiDatePath(date)}${
            target !== undefined ? "/" + target : ""
          }`;
        const { data, status } = await axios.post(url, reports, config);
        const responseData = data as PostResponse;
        if (!responseData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }

        authCtxt.updateToken(responseData.token, new Date(responseData.expiresAt));

        return generateSaveResult(title, status, data as PostResponse);
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { loadMetaData, getReportData, saveReportData };
};
