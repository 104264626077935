import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";
import { ShowEmpty } from "common/components/atoms/ShowEmpty";

import { InquiryData } from "pages/inquiry/types/InquiryInfo";
import { InquiryDetailTable } from "pages/inquiry/components/InquiryDetailTable";
import { useInquiry } from "pages/inquiry/hooks/useInquiry";
import { InquiryMeta } from "pages/inquiry/types/InquiryMeta";

let isInitializing = false;

//=====================================
// 問合せ苦情要望のデータを取得し、テーブルで表示する
//=====================================
export const InquiryTable = (props: { title: string; date: Date }) => {
  const { selectedPark } = useContext(AuthContext);
  const { setErrorMessage } = useContext(StatusContext);
  const [metaData, setMetaData] = useState<InquiryMeta | null>(null);
  const [inquiryData, setInquiryData] = useState<InquiryData[] | null>();
  const { getReportData, loadMetaData } = useInquiry();

  const initialize = async (): Promise<InquiryMeta | null> => {
    if (!selectedPark) {
      return null;
    }
    const { succeeded, msg, data } = await loadMetaData(selectedPark.parkId);
    if (succeeded) {
      setMetaData(data!);
      return data!;
    } else {
      setErrorMessage(msg);
      return null;
    }
  };

  //--------------------------------------
  // 問合せ苦情要望データをロードする
  //--------------------------------------
  const loadInquiries = async () => {
    if (!selectedPark) return;

    const today = props.date;
    const { data } = await getReportData(selectedPark.parkId, today);
    if (data) {
      setInquiryData(data.details ?? []);
      return;
    }
  };

  useEffect(() => {
    const f = async () => {
      if (isInitializing) return;

      let baseData = metaData;
      if (!baseData) {
        isInitializing = true;
        baseData = await initialize();
        isInitializing = false;
        if (!baseData) {
          return;
        }
      }
      loadInquiries();
    };
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Col className="mt-2 mx-2 summary-table agg-table-border">
      <div className="h5"> {props.title} </div>
      {!inquiryData || metaData === null ? (
        <div style={{ margin: "1rem 1.5rem" }}>
          <ShowEmpty>データはありません</ShowEmpty>
        </div>
      ) : (
        <InquiryDetailTable
          Details={inquiryData}
          Meta={metaData}
          readonly={true}
          showTitle={false}
          addDataRequested={() => {}}
          editDataRequested={(data, index) => {}}
          deleteDataRequested={(data) => {}}
        />
      )}
    </Col>
  );
};
