import { useState, useContext, memo, useEffect } from "react";
import { Accordion, Collapse } from "react-bootstrap";
import styled from "styled-components";

import AuthContext from "common/store/AuthContext";
import MenuContext from "common/store/MenuContext";
import { TAB_ID } from "common/types/consts/Defines";

import "../molecules/drawer/style.css";

import { Drawer } from "../molecules/drawer/Drawer";
import { SideMenuItem } from "../molecules/SideMenuItem";
import { ParkTabInfo } from "common/types/ParkInfo";
import { ScrollRestoration } from "react-router-dom";

let scrollPos = 0;

const ILSidebar = memo((props: { className?: string }) => {
  const [open, setOpen] = useState(false);
  const authCtxt = useContext(AuthContext);
  const isAdmin = authCtxt.selectedPark?.isAdmin;
  const {
    activeKeyCommon,
    setActiveKeyCommon,
    activeKeyDaily,
    setActiveKeyDaily,
    activeKeyWork,
    setActiveKeyWork,
    activeKeyOthers,
    setActiveKeyOthers,
    activeKeySettings,
    setActiveKeySettings,
  } = useContext(MenuContext);

  const handleToggle = () => {
    setOpen(!open);
  };

  const findTab = (tabs: ParkTabInfo[] | undefined, tabId: number) => {
    return tabs?.find((tab) => tab.id === tabId);
  };

  const findTabs = (tabs: ParkTabInfo[] | undefined, tabId: number) => {
    return tabs?.filter((tab) => tab.id === tabId);
  };

  var dailyTabs = authCtxt.selectedPark?.dailyTabs;
  const staffsTabDaily = findTab(dailyTabs, TAB_ID.STAFFS);
  const visitorsTab = findTab(dailyTabs, TAB_ID.VISITORS);
  const inquiryTab = findTab(dailyTabs, TAB_ID.INQUIRY);
  const exclusiveUsesTab = findTab(dailyTabs, TAB_ID.EXCLUSIVE_USE);
  const incidentsTab = findTab(dailyTabs, TAB_ID.INCIDENTS);
  const payFacilityTab = findTab(dailyTabs, TAB_ID.PAY_FACILITY);
  const independentEventTabs = findTabs(dailyTabs, TAB_ID.PRIVATE_BIZ);
  const collaborateEventTabs = findTabs(dailyTabs, TAB_ID.PUBLIC_BIZ);
  const specialNoteTabDaily = findTab(dailyTabs, TAB_ID.SPECIAL_NOTE);
  const commentTabDaily = findTab(dailyTabs, TAB_ID.COMMENT);
  const periodEventTab = findTab(dailyTabs, TAB_ID.PERIODICAL_EVENT);

  var workTabs = authCtxt.selectedPark?.workTabs;
  const staffsTabWork = findTab(workTabs, TAB_ID.STAFFS);
  const workReportTab = findTab(workTabs, TAB_ID.WORK_REPORT);
  const patrolTab = findTab(workTabs, TAB_ID.PATROL);
  const parkWorkTab = findTab(workTabs, TAB_ID.PARK_WORK);
  const specialNoteTabWork = findTab(workTabs, TAB_ID.SPECIAL_NOTE);
  const commentTabWork = findTab(workTabs, TAB_ID.COMMENT);

  const toCategoryPath = (categoryId?: number) => {
    return categoryId !== undefined ? `/${categoryId}` : "";
  };

  useEffect(() => {
    const barDiv = document.getElementsByClassName("target_sidebar")[0];

    if (!barDiv) return;
    barDiv.scrollTo(0, scrollPos);

    const onScroll = () => {
      scrollPos = barDiv.scrollTop;
    };

    barDiv.addEventListener("scrollend", onScroll);
    return () => {
      barDiv.removeEventListener("scrollend", onScroll);
    };
  }, []);

  return (
    <SDrawer className={`${props.className}`}>
      <Drawer.Toggle onClick={handleToggle} />
      <Collapse in={open}>
        <Drawer.Overflow>
          <SDrawerToC className="pe-1 target_sidebar">
            <ScrollRestoration />
            <Accordion activeKey={activeKeyCommon}>
              <SHeader onClick={() => setActiveKeyCommon(activeKeyCommon === "" ? "common" : "")}>
                共通
              </SHeader>
              <Accordion.Item eventKey="common">
                <SAccordionBody>
                  <SideMenuItem title="ホーム" iconClassName="fa fas fa-home" to="/" />
                  <SideMenuItem title="出力" iconClassName="fa fas fa-print" to="/print" />
                </SAccordionBody>
              </Accordion.Item>
            </Accordion>
            <Accordion activeKey={activeKeyDaily}>
              <SHeader onClick={() => setActiveKeyDaily(activeKeyDaily === "" ? "daily" : "")}>
                公園日報
              </SHeader>
              <Accordion.Item eventKey="daily">
                <SAccordionBody>
                  {/* 勤務者 */}
                  {staffsTabDaily && (
                    <SideMenuItem
                      title={staffsTabDaily.name}
                      iconClassName="fas fa-user-check"
                      to="/parkstaffs/daily"
                    />
                  )}
                  {/* 利用者数 */}
                  {visitorsTab && (
                    <SideMenuItem
                      title={visitorsTab.name}
                      iconClassName="fas fa-users"
                      to="/visitors"
                    />
                  )}
                  {/* 問合せ苦情要望 */}
                  {inquiryTab && (
                    <SideMenuItem
                      title={inquiryTab.name}
                      iconClassName="fas fa-phone"
                      to="/inquiry"
                    />
                  )}
                  {/* 一時占用 */}
                  {exclusiveUsesTab && (
                    <SideMenuItem
                      title={exclusiveUsesTab.name}
                      iconClassName="fas fa-photo-film"
                      to="/exclusiveuses"
                    />
                  )}
                  {incidentsTab && (
                    <SideMenuItem
                      title={incidentsTab.name}
                      iconClassName="fas fa-person-falling-burst"
                      to="/incidents"
                    />
                  )}
                  {/* 有料施設 */}
                  {payFacilityTab && (
                    <SideMenuItem
                      title={payFacilityTab.name}
                      iconClassName="fas fa-sack-dollar"
                      to="/payfacility"
                    />
                  )}
                  {/* 自主事業 */}
                  {independentEventTabs &&
                    independentEventTabs.map((x) => (
                      <SideMenuItem
                        key={`prvBiz_${x.id}_${x.categoryId ?? 0}`}
                        title={x.name}
                        iconClassName="fas fa-calendar-days"
                        to={`/parkEvents/independent${toCategoryPath(x.categoryId)}`}
                      />
                    ))}
                  {/* 住民協働事業 */}
                  {collaborateEventTabs &&
                    collaborateEventTabs.map((x) => (
                      <SideMenuItem
                        key={`coopBiz_${x.id}_${x.categoryId ?? 0}`}
                        title={x.name}
                        iconClassName="far fa-calendar-days"
                        to={`/parkEvents/collaborate${toCategoryPath(x.categoryId)}`}
                      />
                    ))}
                  {/* 特記メモ */}
                  {specialNoteTabDaily && (
                    <SideMenuItem
                      title={specialNoteTabDaily.name}
                      iconClassName="fa fa-file-pen"
                      to="/specialnotes/daily"
                    />
                  )}
                  {/* コメント */}
                  {commentTabDaily && (
                    <SideMenuItem
                      title={commentTabDaily.name}
                      iconClassName="far fa-comment-dots"
                      to="/comments/daily"
                    />
                  )}
                </SAccordionBody>
              </Accordion.Item>
            </Accordion>

            <Accordion activeKey={activeKeyWork}>
              <SHeader onClick={() => setActiveKeyWork(activeKeyWork === "" ? "work" : "")}>
                作業日報
              </SHeader>
              <Accordion.Item eventKey="work">
                <SAccordionBody>
                  {/* 勤務者（作業日報） */}
                  {staffsTabWork && (
                    <SideMenuItem
                      title={staffsTabWork.name}
                      iconClassName="fas fa-user-check"
                      to="/parkstaffs/work"
                    />
                  )}
                  {/* 巡回作業日報 */}
                  {workReportTab && (
                    <SideMenuItem
                      title={workReportTab?.name}
                      iconClassName="fas fa-walkie-talkie"
                      to="/workreport"
                    />
                  )}
                  {/* 巡回のみ */}
                  {patrolTab && (
                    <SideMenuItem
                      title={patrolTab?.name}
                      iconClassName="fas fa-road-circle-check"
                      to="/workreport/patrol"
                    />
                  )}
                  {/* 作業のみ */}
                  {parkWorkTab && (
                    <SideMenuItem
                      title={parkWorkTab?.name}
                      iconClassName="fas fa-dolly"
                      to="/workreport/work"
                    />
                  )}
                  {/* 特記メモ（作業日報） */}
                  {specialNoteTabWork && (
                    <SideMenuItem
                      title={specialNoteTabWork?.name}
                      iconClassName="fa fa-file-pen"
                      to="/specialnotes/work"
                    />
                  )}
                  {/* コメント（作業日報） */}
                  {commentTabWork && (
                    <SideMenuItem
                      title={commentTabWork.name}
                      iconClassName="fa fa-comment-dots"
                      to="/comments/work"
                    />
                  )}
                </SAccordionBody>
              </Accordion.Item>
            </Accordion>

            {/* その他 */}
            {periodEventTab && (
              <Accordion activeKey={activeKeyOthers}>
                <SHeader onClick={() => setActiveKeyOthers(activeKeyOthers === "" ? "others" : "")}>
                  その他
                </SHeader>
                <Accordion.Item eventKey="others">
                  <SAccordionBody>
                    <SideMenuItem
                      title="期間イベント"
                      iconClassName="far fa-calendar-check"
                      to="/parkevents/periodical"
                    />
                  </SAccordionBody>
                </Accordion.Item>
              </Accordion>
            )}

            {/* 設定 */}
            {isAdmin && (
              <Accordion activeKey={activeKeySettings}>
                <SHeader
                  onClick={() => setActiveKeySettings(activeKeySettings === "" ? "settings" : "")}
                >
                  設定
                </SHeader>
                <Accordion.Item eventKey="settings">
                  <SAccordionBody>
                    <SideMenuItem
                      title="帳票登録"
                      iconClassName="fas fa-newspaper"
                      to="/settings/printtemplate"
                    />
                    <SideMenuItem
                      title="勤務者設定"
                      iconClassName="fas fa-user-gear"
                      to="/settings/staffs"
                    />
                  </SAccordionBody>
                </Accordion.Item>
              </Accordion>
            )}
          </SDrawerToC>
        </Drawer.Overflow>
      </Collapse>
    </SDrawer>
  );
});

export default ILSidebar;

const SDrawer = styled(Drawer)`
  background-color: transparent;
  border: none;
  &.react-bootstrap-drawer {
    top: 0;
  }
`;

const SDrawerToC = styled(Drawer.ToC)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SHeader = styled(Accordion.Header)`
  .accordion-button {
    padding: 0.5rem;
    background-color: ${(props) => props.theme.color.menuHeader};
    color: ${(props) => props.theme.color.menuHeaderText};
    &.collapsed {
      background-color: ${(props) => props.theme.color.menuHeader};
      color: ${(props) => props.theme.color.menuHeaderText};
    }
  }
  border-radius: 1rem;
`;

const SAccordionBody = styled(Accordion.Body)`
  padding: 0;
`;
