import { useState } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { getDateString } from "utils/commonTools";
import { ILCalendarDlg } from "common/components/organisms/ILCalendarDlg";

type Props = {
  date: Date;
  dateChanged: (newValue: Date) => void;
};

export const SelectDayControl = ({ date, dateChanged }: Props) => {
  const [showCalendar, setShowCalendar] = useState(false);

  const dateSelected = (newDate: Date) => {
    dateChanged(newDate);
    setShowCalendar(false);
  };

  return (
    <SDateDiv>
      <div> 日付： {getDateString(date)}</div>
      <Button
        variant="outline-info"
        size="sm"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          setShowCalendar(true);
        }}
      >
        <i className="fas fa-calendar-days" />
      </Button>
      {showCalendar && (
        <ILCalendarDlg
          show={showCalendar}
          initialValue={date}
          onOK={dateSelected}
          onCancel={() => setShowCalendar(false)}
        />
      )}
    </SDateDiv>
  );
};

const SDateDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
`;
