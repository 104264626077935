import styled from "styled-components";
import { Form } from "react-bootstrap";

import MyNumberInput from "common/components/atoms/MyNumberInput";
import { Dispatch, SetStateAction } from "react";

type Params = {
  year: number;
  yearChanged: Dispatch<SetStateAction<number>>;
};

export const SelectFiscalYearControl = ({ year, yearChanged }: Params) => {
  return (
    <SSelectRootDiv style={{ width: "150px" }}>
      <MyNumberInput
        initialValue={year}
        min={2000}
        max={new Date().getFullYear()}
        changed={(newValue) => {
          yearChanged(newValue);
        }}
        style={{ lineHeight: "1.5", width: "100px" }}
      />
      <Form.Label>年度</Form.Label>
    </SSelectRootDiv>
  );
};

const SSelectRootDiv = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  direction: col;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: baseline;
`;
