import { useEffect, useState } from "react";
import { Form, ListGroup } from "react-bootstrap";
import styled from "styled-components";

import { Staffs } from "common/types/Staffs";
import { SelectStaffsDlg } from "./SelectStaffsDlg";

type Props = {
  lists: Staffs;
  selecteds: Staffs;
  stringWhenEmpty: string;
  readonly: boolean;
};

export const StaffsList = (props: Props) => {
  const { lists, selecteds, readonly } = props;
  const [vals, setVals] = useState(selecteds);
  const [showDlg, setShowDlg] = useState(false);

  useEffect(() => {
    setVals(selecteds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selecteds]);

  return (
    <>
      <ListGroup as="ul" style={{ maxHeight: "200px", overflow: "auto", fontSize: "14px" }}>
        {vals.fulltime.length + vals.parttime.length + vals.tempstaff.length === 0 && (
          <SListItem
            readOnly={readonly}
            onClick={(e: MouseEvent) => {
              if (readonly) return;
              setShowDlg(true);
            }}
          >
            <Form.Label>{props.stringWhenEmpty}</Form.Label>
          </SListItem>
        )}
        {vals.fulltime.map((full) => (
          <SListItem
            readOnly={readonly}
            key={`full_${full.employeeID}`}
            onClick={(e: MouseEvent) => {
              e.preventDefault();
              if (readonly) return;
              setShowDlg(true);
            }}
          >
            <Form.Label>{full.employeeName}</Form.Label>
          </SListItem>
        ))}
        {vals.parttime.map((parttime) => (
          <SListItem
            readOnly={readonly}
            key={`part_${parttime.employeeID}`}
            onClick={(e: MouseEvent) => {
              e.preventDefault();
              if (readonly) return;
              setShowDlg(true);
            }}
          >
            <Form.Label>{parttime.employeeName}</Form.Label>
          </SListItem>
        ))}
        {vals.tempstaff.map((tempStaff) => (
          <SListItem
            readOnly={readonly}
            key={`temp_${tempStaff.employeeID}`}
            onClick={(e: MouseEvent) => {
              e.preventDefault();
              if (readonly) return;
              setShowDlg(true);
            }}
          >
            <Form.Label>{tempStaff.employeeName}</Form.Label>
          </SListItem>
        ))}
      </ListGroup>
      <SelectStaffsDlg
        show={showDlg}
        lists={lists}
        data={vals}
        onOK={(newVals) => {
          setVals(newVals);
          setShowDlg(false);
        }}
        onCancel={() => {
          setShowDlg(false);
        }}
      />
    </>
  );
};

const SListItem = styled(ListGroup.Item)<{ readOnly: boolean }>`
  cursor: ${(props) => (props.readOnly ? "default" : "pointer")};
`;
