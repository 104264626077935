import { memo } from "react";
import { Table } from "react-bootstrap";

import { ParkInfo } from "common/types/ParkInfo";

import PrimaryButton from "common/components/atoms/PrimaryButton";
import FormContainer from "common/components/templates/FormContainer";

type Props = {
  parks: Array<ParkInfo> | null;
  parkSelected: (park: ParkInfo) => void;
};

export const SelectParkPanel = memo((props: Props) => {
  const { parks, parkSelected } = props;

  const selectParkHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const parkId = event.currentTarget.getAttribute("value");
    if (!parkId) return;

    execSelectPark(+parkId);
  };

  const execSelectPark = (parkId: number) => {
    const park = parks?.find((park) => +parkId === park.parkId);
    if (park) {
      parkSelected(park);
    }
  };

  return (
    <FormContainer size="medium">
      <div className="card shadow mb-4 mt-2 px-2">
        <h2 className="my-3">公園選択</h2>

        {!parks && <div> 公園がありません </div>}
        {parks && (
          <Table bordered hover responsive>
            <thead>
              <tr className="text-center">
                <th>公園名</th>
                <th>編集権限</th>
                <th>管理者</th>
                <th>選択</th>
              </tr>
            </thead>
            <tbody>
              {parks.map((park) => {
                return (
                  <tr key={`park_${park.parkId}`}>
                    <td> {park.parkName} </td>
                    <td className="col-sm-2 text-center"> {park.canWrite ? "〇" : "-"}</td>
                    <td className="col-sm-2 text-center"> {park.isAdmin ? "〇" : "-"}</td>
                    <td className="col-sm-2 text-center">
                      <PrimaryButton
                        disabled={park.permission === 0}
                        value={park.parkId}
                        onClick={() => execSelectPark(park.parkId)}
                        title={park.permission !== 0 ? "選択" : "利用不可"}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    </FormContainer>
  );
});
