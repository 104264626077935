import styled from "styled-components";
import { CancelButton } from "common/components/atoms/CancelButton";
import PrimaryButton from "common/components/atoms/PrimaryButton";
import { StaffInfo, Staffs } from "common/types/Staffs";
import { useEffect, useState } from "react";
import { Form, ListGroup, Modal } from "react-bootstrap";

type Props = {
  show: boolean;
  lists: Staffs;
  data: Staffs;
  onOK: (vals: Staffs) => void;
  onCancel: () => void;
};

export const SelectStaffsDlg = (props: Props) => {
  const { show, lists, data, onOK, onCancel } = props;
  const [fulltimes, setFulltimes] = useState<StaffInfo[]>([]);
  const [parttimes, setParttimes] = useState<StaffInfo[]>([]);
  const [tempStaffs, setTempstaffs] = useState<StaffInfo[]>([]);

  useEffect(() => {
    setFulltimes(data.fulltime);
    setParttimes(data.parttime);
    setTempstaffs(data.tempstaff);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Modal show={show} variant="info">
      <SModalBody>
        <ListGroup as="ul" style={{ overflow: "auto" }}>
          {lists?.fulltime &&
            lists.fulltime.map((full) => (
              <ListGroup.Item key={`full_${full.employeeID}`}>
                <Form.Check
                  label={full.employeeName}
                  checked={fulltimes.find((x) => x.employeeID === full.employeeID) !== undefined}
                  onChange={(e) => {
                    if (e.target.checked) {
                      data.fulltime = [...data.fulltime, full];
                    } else {
                      data.fulltime = data.fulltime.filter((x) => x.employeeID !== full.employeeID);
                    }
                    setFulltimes(data.fulltime);
                  }}
                />
              </ListGroup.Item>
            ))}
          {lists?.parttime &&
            lists.parttime.map((parttime) => (
              <ListGroup.Item key={`part_${parttime.employeeID}`}>
                <Form.Check
                  label={parttime.employeeName}
                  checked={
                    parttimes.find((x) => x.employeeID === parttime.employeeID) !== undefined
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      data.parttime = [...data.parttime, parttime];
                    } else {
                      data.parttime = data.parttime.filter(
                        (x) => x.employeeID !== parttime.employeeID
                      );
                    }
                    setParttimes(data.parttime);
                  }}
                />
              </ListGroup.Item>
            ))}
          {lists?.tempstaff &&
            lists.tempstaff.map((tempStaff) => (
              <ListGroup.Item key={`temp_${tempStaff.employeeID}`}>
                <Form.Check
                  label={tempStaff.employeeName}
                  checked={
                    tempStaffs.find((x) => x.employeeID === tempStaff.employeeID) !== undefined
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      data.tempstaff = [...data.tempstaff, tempStaff];
                    } else {
                      data.tempstaff = data.tempstaff.filter(
                        (x) => x.employeeID !== tempStaff.employeeID
                      );
                    }
                    setTempstaffs(data.tempstaff);
                  }}
                />
              </ListGroup.Item>
            ))}
        </ListGroup>
      </SModalBody>
      <Modal.Footer>
        <CancelButton onClick={onCancel} />
        <PrimaryButton
          className="mx-2"
          onClick={() => {
            onOK(data);
          }}
          title="OK"
        />
      </Modal.Footer>
    </Modal>
  );
};

const SModalBody = styled(Modal.Body)`
  max-height: 350px;
  overflow: auto;
`;
