import styled from "styled-components";
import PrimaryButton from "common/components/atoms/PrimaryButton";
import { StaffMasterData } from "../types/StaffMasterInfo";
import { NameID } from "common/types/NameID";
import { useEffect, useState } from "react";
import { Col, Form, Modal } from "react-bootstrap";
import { CancelButton } from "common/components/atoms/CancelButton";
import MyTextbox from "common/components/atoms/MyTextbox";
import MyNumberInput from "common/components/atoms/MyNumberInput";
import MyCheckbox from "common/components/atoms/MyCheckbox";

type Props = {
  target: StaffMasterData;
  posts: NameID[];
  onOK: (data: StaffMasterData) => void;
  onCancel: () => void;
};

export const EditStaffDlg = (props: Props) => {
  const { target, posts, onOK, onCancel } = props;
  const [item, setItem] = useState<StaffMasterData>();
  const [sortedPosts, setSortedPosts] = useState<NameID[]>([]);

  const postSorter = (a: NameID, b: NameID) => {
    const id1 = a.id < 0 ? -a.id + 99999 : a.id;
    const id2 = b.id < 0 ? -b.id + 99999 : b.id;
    return id1 > id2 ? 1 : -1;
  };

  useEffect(() => {
    setItem({ ...target });
    setSortedPosts(posts.sort(postSorter));
  }, [posts, target]);

  if (!item) return <div>データがありません</div>;

  return (
    <Modal show={target !== null} size="sm">
      <Modal.Header>{target.employeeID >= 0 ? "勤務者の編集" : "勤務者の追加"}</Modal.Header>
      <Modal.Body>
        <SRootDiv>
          <FormGroup as={Col}>
            <SRowTitle>名前</SRowTitle>
            <MyTextbox
              initialValue={item.employeeName}
              changed={(value) => setItem({ ...item, employeeName: value })}
              readonly={false}
            />
          </FormGroup>
          <FormGroup as={Col}>
            <SRowTitle>役職</SRowTitle>
            <Form.Select
              aria-label="役職"
              value={item.postID}
              onChange={(e) => {
                e.preventDefault();
                const postID = +e.currentTarget.value;
                const postName = posts.find((x) => x.id === postID)?.name ?? "";
                setItem({ ...item, postID, postName });
              }}
            >
              {sortedPosts.map((post) => (
                <option key={`post_${post.id}`} value={post.id}>
                  {post.name}
                </option>
              ))}
            </Form.Select>
          </FormGroup>
          <FormGroup as={Col}>
            <SRowTitle>表示順</SRowTitle>
            <div style={{ width: "50px" }}>
              <MyNumberInput
                initialValue={item.displayOrder}
                changed={(value) => {
                  item.displayOrder = value;
                }}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <MyCheckbox
              initialValue={item.isWorking}
              changed={(value) => (item.isWorking = value)}
              label="現職"
            />
          </FormGroup>
        </SRootDiv>
      </Modal.Body>
      <Modal.Footer>
        <CancelButton
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
        />
        {item && (
          <PrimaryButton
            className="mx-2"
            onClick={() => onOK(item!)}
            title="決定"
            disabled={item.employeeName === ""}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

const SRootDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FormGroup = styled(Form.Group)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const SRowTitle = styled.span`
  width: 80px;
`;
