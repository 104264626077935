import styled, { CSSProperties } from "styled-components";
import DatePicker, { DateObject } from "react-multi-date-picker";
import gregorian_ja from "../organisms/configs/gregorian_ja";
import { getHolidayName } from "utils/commonTools";
import { CancelButton } from "./CancelButton";

type Props = {
  value?: Date;
  onChange: (val?: Date) => void;
  canClear?: boolean;
  readOnly?: boolean;
  style?: CSSProperties;
};

export const MyDatePicker = (props: Props) => {
  const { value, canClear = true, readOnly = false, style } = props;

  const onChange = (val: DateObject | DateObject[] | null) => {
    if (val === null) {
      props.onChange(undefined);
      return;
    }
    const dateObject = val as DateObject;
    const dt = dateObject.toDate();
    dt.setHours(0, 0, 0);
    props.onChange(dt);
  };

  return (
    <SRootDiv>
      <DatePicker
        readOnly={readOnly}
        style={{ ...style, padding: "1rem 1rem" }}
        locale={gregorian_ja}
        value={value}
        onChange={onChange}
        mapDays={({ date }) => {
          let className = "";
          if (getHolidayName(date.toDate())) {
            className = "highlight highlight-red";
          }

          return { className };
        }}
      />
      {canClear && <CancelButton onClick={() => props.onChange(undefined)} text="クリアー" />}
    </SRootDiv>
  );
};

const SRootDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
