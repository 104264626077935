import { useCallback, useContext } from "react";
import axios from "axios";
import AuthContext from "common/store/AuthContext";

import { generateErrorMsg, getServerUrl } from "utils/commonTools";
import { toJapaneseDate } from "utils/DateTools";

import { TAB_ID } from "common/types/consts/Defines";
import { AggregateData, ByMonth } from "../types/AggregateData";
import { GetBasicResponse } from "common/types/responses/BasicResponse";

export const useAggregate = () => {
  const authCtxt = useContext(AuthContext);
  const { token } = authCtxt;

  const getAggregateData = useCallback(
    async (
      parkId: number,
      from: Date,
      to: Date,
      targets: TAB_ID[]
    ): Promise<{ succeeded: boolean; msg: string; data?: ByMonth[] }> => {
      try {
        const config = {
          headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
        };

        const queryParam = `from=${toJapaneseDate(from)}&to=${toJapaneseDate(to)}&${targets
          .map((x) => "target=" + x.valueOf())
          .join("&")}`;

        const { data } = await axios.get(
          getServerUrl() + `aggregate/bymonth/${parkId}?${queryParam}`,
          config
        );

        const byMonthRes = data as GetBasicResponse<AggregateData>;
        if (!byMonthRes) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }

        authCtxt.updateToken(byMonthRes.token, new Date(byMonthRes.expiresAt));

        return {
          succeeded: true,
          msg: "",
          data: byMonthRes.data.monthData,
        };
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    [authCtxt, token]
  );

  return { getAggregateData };
};
