import { ReactNode } from "react";
import styled from "styled-components";

export const PrintPanelTemplate = (props: { children: ReactNode }) => {
  return <SRootDiv className="p-2">{props.children}</SRootDiv>;
};

const SRootDiv = styled.div`
  min-width: 400px;
  box-shadow: 1px 1px 3px #888;
`;
