import { Modal, Image } from "react-bootstrap";
import { RegisteredFileInfo } from "common/types/files/RegisteredFileInfo";

type Props = {
  image: string;
  file: RegisteredFileInfo;
  show: boolean;
  onClose: () => void;
};

export const ShowPictureDlg = (props: Props) => {
  const { image, file, show, onClose } = props;

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Body>
        <Image src={image} alt={file.fileName} className="w-100" />
      </Modal.Body>
    </Modal>
  );
};
