import { memo, useContext } from "react";
import styled from "styled-components";
import { LinkContainer } from "react-router-bootstrap";
import StatusContext from "common/store/StatusContext";

type Props = {
  children: React.ReactNode;
  to: string | object;
  className?: string;
  style?: any;
};

export const ILLinkContainer = memo(({ children, to, className, style }: Props) => {
  const { isEditing } = useContext(StatusContext);
  const disabled = isEditing;

  return (
    <SLinkContainer
      to={disabled ? "" : to}
      className={`${className} ${disabled && "disabled"}`}
      style={style}
    >
      {children}
    </SLinkContainer>
  );
});

const SLinkContainer = styled(LinkContainer)`
  &.disabled {
    cursor: default;
  }
`;
