import { useCallback, useContext } from "react";
import axios from "axios";

import AuthContext from "common/store/AuthContext";
import {
  generateErrorMsg,
  genApiDatePath,
  getServerUrl,
  generateSaveResult,
} from "utils/commonTools";
import { GetBasicResponse, PostBasicResponse, PostResponse } from "common/types/responses/BasicResponse";
import { LastUpdatedInfo } from "common/types/LastUpdatedInfo";

import { PayFacilitiesMeta } from "../types/PayFacilityMeta";
import { PayFacilityReport } from "../types/PayFacilityData";

///////////////////////////////////////////////////////////////////////////////
// 有料施設関係のサーバーアクセス処理
export const usePayFacility = () => {
  const authCtxt = useContext(AuthContext);
  const { token } = authCtxt;

  //--------------------------------------
  // 有料施設のメタデータ(選択情報）をサーバーから取得する
  //--------------------------------------
  const loadMetaData = useCallback(
    async (
      parkId: number
    ): Promise<{ succeeded: boolean; msg: string; data?: PayFacilitiesMeta }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };

      try {
        const { data } = await axios.get(getServerUrl() + `payFacility/meta/${parkId}`, config);
        const metaData = data as GetBasicResponse<PayFacilitiesMeta>;
        if (!metaData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }
        return {
          succeeded: true,
          msg: "",
          data: metaData.data,
        };
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //--------------------------------------
  // 日報データをサーバーから取得する
  //--------------------------------------
  const getReportData = useCallback(
    async (
      parkId: number,
      date: Date
    ): Promise<{
      succeeded: boolean;
      msg: string;
      data?: PayFacilityReport;
      lastUpdated?: LastUpdatedInfo;
    }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };
      try {
        // 日報データの取得
        const url = getServerUrl() + `payFacility/report/${parkId}/${genApiDatePath(date)}`;

        const { data } = await axios.get(url, config);
        const reportData = data as PostBasicResponse<PayFacilityReport>;
        if (!reportData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }

        authCtxt.updateToken(reportData.token, new Date(reportData.expiresAt));

        return {
          succeeded: true,
          msg: "",
          data: reportData.data,
          lastUpdated: reportData.lastUpdated,
        };
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //--------------------------------------
  // 保存処理の実行
  //--------------------------------------
  const saveReportData = useCallback(
    async (
      parkId: number,
      date: Date,
      reportData: PayFacilityReport
    ): Promise<{ succeeded: boolean; msg: string; lastUpdated?: LastUpdatedInfo }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };
      try {
        const url = getServerUrl() + `payFacility/report/${parkId}/${genApiDatePath(date)}`;
        const { data, status } = await axios.post(url, reportData, config);
        const responseData = data as PostResponse;
        if (!responseData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }

        authCtxt.updateToken(responseData.token, new Date(responseData.expiresAt));

        return generateSaveResult("有料施設", status, data as PostResponse);
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    loadMetaData,
    getReportData,
    saveReportData,
  };
};
