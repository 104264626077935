import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";

import AuthContext from "common/store/AuthContext";
import { ShowEmpty } from "common/components/atoms/ShowEmpty";

import { useVisitors } from "pages/visitors/hooks/useVisitors";
import { VisitorReport } from "pages/visitors/types/VisitorReport";
import { VisitorTable } from "pages/visitors/components/VisitorTable";

//=====================================
// 利用者数のデータを取得し、テーブルで表示する
//=====================================
export const VisitorsTable = (props: { title: string; date: Date }) => {
  const { selectedPark } = useContext(AuthContext);

  const [visitorData, setVisitorData] = useState<VisitorReport | null>(null);
  const { getReportData } = useVisitors();

  //--------------------------------------
  // 利用者数データをロードする
  //--------------------------------------
  const loadInquiries = async () => {
    if (!selectedPark) return;

    const { data } = await getReportData(selectedPark.parkId, props.date);
    if (data) {
      setVisitorData(data);
      return;
    }
  };

  useEffect(() => {
    const f = async () => loadInquiries();
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Col className="mt-2 mx-2 summary-table agg-table-border">
      <div className="h5"> {props.title} </div>
      {!visitorData ? (
        <div style={{ margin: "1rem 1.5rem" }}>
          <ShowEmpty>データはありません</ShowEmpty>
        </div>
      ) : (
        <VisitorTable
          title={""}
          columnTitle="来園場所"
          data={visitorData.places ?? []}
          readOnly={true}
        />
      )}
    </Col>
  );
};
