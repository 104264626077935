import { Table } from "react-bootstrap";
import styled from "styled-components";
import MyCheckbox from "common/components/atoms/MyCheckbox";
import FunctionButton from "common/components/atoms/FunctionButton";

import { useAreas } from "common/hooks/useAreas";
import { InquiryData } from "../types/InquiryInfo";
import { RowEditButtons } from "common/components/molecules/RowEditButtons";
import { MultiLineText } from "common/components/atoms/MultiLineText";
import { InquiryMeta } from "../types/InquiryMeta";
import { CustomInput } from "common/types/CustomInput";
import { InquiryTableDefs } from "../types/InquiryTableDef";

type Props = {
  Details: Array<InquiryData> | null;
  Meta: InquiryMeta;
  readonly: boolean;
  addDataRequested: () => void;
  editDataRequested: (data: InquiryData, index: number) => void;
  deleteDataRequested: (data: InquiryData) => void;
  showTitle?: boolean;
};

export const InquiryDetailTable = ({
  Details,
  Meta,
  readonly,
  addDataRequested,
  editDataRequested,
  deleteDataRequested,
  showTitle = true,
}: Props) => {
  const { toAreaString } = useAreas();

  const getCustomValue = (data: InquiryData, target: CustomInput) => {
    if (!data.customData) return <></>;
    const found = data.customData.find((x) => x.target.id === target.id);
    if (!found) return <></>;

    switch (target.type) {
      case "text":
        return <span>{found.text ?? ""}</span>;
      case "check":
        return (
          <MyCheckbox
            initialValue={found.checked ?? false}
            changed={(_value) => {}}
            readonly={true}
          />
        );
      case "choice":
        return <span>{found.selected?.name ?? ""}</span>;
      default:
        return <></>;
    }
  };

  const isColumnVisible = (name: string): boolean => {
    if (!Meta.customInputs) return true;
    return Meta.customInputs.changes.find((x) => x.title === name)?.visible ?? true;
  };

  const columnTitle = (name: string): string => {
    if (!Meta.customInputs) return name;
    return Meta.customInputs.changes.find((x) => x.title === name)?.rename ?? name;
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mx-2 my-2">
        {showTitle && <h4>詳細入力</h4>}
        {!readonly && (
          <FunctionButton
            onClick={(e) => {
              e.preventDefault();
              addDataRequested();
            }}
            className="ms-2"
            tooltip="追加"
          >
            <i className="fa-regular fa-plus"></i>
          </FunctionButton>
        )}
      </div>
      <Table bordered hover responsive>
        <thead>
          <tr className="text-center">
            <th className="col-md-1" hidden={true}>
              整理番号
            </th>
            <th className="col-md-1" hidden={!isColumnVisible(InquiryTableDefs.Category)}>
              {/* 要求種別 */}
              {columnTitle(InquiryTableDefs.Category)}
            </th>
            <th className="col-md-1" hidden={!isColumnVisible(InquiryTableDefs.Method)}>
              {/* 受付種別 */}
              {columnTitle(InquiryTableDefs.Method)}
            </th>
            <th className="col-md-1" hidden={!isColumnVisible(InquiryTableDefs.Recipient)}>
              {/* 受付者 */}
              {columnTitle(InquiryTableDefs.Recipient)}
            </th>
            {/* カスタム入力 */}
            {Meta.customInputs?.customs.map((custom) => (
              <Sth key={`inq_custom_th_${custom.id}`} type={custom.type}>
                {custom.title}
              </Sth>
            ))}
            <th
              className="col-md-*"
              style={{ minWidth: "100px" }}
              hidden={!isColumnVisible(InquiryTableDefs.Contents)}
            >
              {/* 内容 */}
              {columnTitle(InquiryTableDefs.Contents)}
            </th>
            <th
              className="col-md-*"
              style={{ minWidth: "100px" }}
              hidden={!isColumnVisible(InquiryTableDefs.Action)}
            >
              {/* 対応内容 */}
              {columnTitle(InquiryTableDefs.Action)}
            </th>
            <th className="col-md-1" hidden={!isColumnVisible(InquiryTableDefs.NumIncident)}>
              {/* 件数 */}
              {columnTitle(InquiryTableDefs.NumIncident)}
            </th>
            <th className="col-md-1" hidden={!isColumnVisible(InquiryTableDefs.Completed)}>
              {/* 完了 */}
              {columnTitle(InquiryTableDefs.Completed)}
            </th>
            <th className="col-md-1" hidden={!isColumnVisible(InquiryTableDefs.Reported)}>
              {/* 報告 */}
              {columnTitle(InquiryTableDefs.Reported)}
            </th>
            <th className="col-md-1" hidden={!isColumnVisible(InquiryTableDefs.Areas)}>
              {/* 地区 */}
              {columnTitle(InquiryTableDefs.Areas)}
            </th>
            {!readonly && <th style={{ width: "100px" }}>操作</th>}
          </tr>
        </thead>
        <tbody>
          {Details &&
            Details.map((x, index) => (
              <tr key={`inq_${index}`}>
                <td className="text-center" hidden={true}>
                  {x.inquiryNo}
                </td>
                <td className="text-center" hidden={!isColumnVisible(InquiryTableDefs.Category)}>
                  {x.category?.name}
                  {x.subCategory?.name && `/${x.subCategory?.name}`}
                </td>
                <td className="text-center" hidden={!isColumnVisible(InquiryTableDefs.Method)}>
                  {x.method?.name}
                </td>
                <td className="text-center" hidden={!isColumnVisible(InquiryTableDefs.Recipient)}>
                  {x.recipient?.name}
                </td>
                {Meta.customInputs?.customs.map((custom) => (
                  <Std key={`inq_custom_${index}_${custom.id}`} type={custom.type}>
                    {getCustomValue(x, custom)}
                  </Std>
                ))}
                <td hidden={!isColumnVisible(InquiryTableDefs.Contents)}>
                  <MultiLineText text={x.contents} />
                </td>
                <td hidden={!isColumnVisible(InquiryTableDefs.Action)}>
                  <MultiLineText text={x.action} />
                </td>
                <td className="text-end" hidden={!isColumnVisible(InquiryTableDefs.NumIncident)}>
                  {x.numIncident}
                </td>
                <td className="text-center" hidden={!isColumnVisible(InquiryTableDefs.Completed)}>
                  <MyCheckbox initialValue={x.completed} readonly={true} />
                </td>
                <td className="text-center" hidden={!isColumnVisible(InquiryTableDefs.Reported)}>
                  <MyCheckbox initialValue={x.reported} readonly={true} />
                </td>
                <td hidden={!isColumnVisible(InquiryTableDefs.Areas)}>
                  <MultiLineText text={toAreaString(x.areas)} />
                </td>
                {!readonly && (
                  <td className="text-center">
                    <RowEditButtons
                      editRequested={() => editDataRequested(x, index)}
                      deleteRequested={() => deleteDataRequested(x)}
                    />
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

const Sth = styled.th<{ type: "text" | "check" | "choice" }>`
  min-width: ${(props) => (props.type === "text" ? 100 : 50)}px;
`;

const Std = styled.td<{ type: "text" | "check" | "choice" }>`
  text-align: ${(props) => (props.type === "check" ? "center" : "left")};
`;
