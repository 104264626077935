import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";
import { BasicTemplate } from "common/components/templates/BasicTemplate";

import { useAuth } from "./hooks/useAuth";
import { ChangePasswordPanel } from "./components/ChangePasswordPanel";

function ChangePasswordPage() {
  const authCtxt = useContext(AuthContext);
  const { setErrorMessage, showToastMessage } = useContext(StatusContext);

  const navigate = useNavigate();

  const { changePassword } = useAuth();

  useEffect(() => {
    document.title = "InfoLand2：パスワード変更";
  }, []);

  //--------------------------------------
  // パスワード変更処理の実行
  //--------------------------------------
  const changePasswordRequested = async (oldPassword: string, newPassword: string) => {
    const { succeeded, msg } = await changePassword(oldPassword, newPassword);
    if (succeeded) {
      showToastMessage("", "パスワードを変更しました。ログアウトします");
      setTimeout(() => {
        navigate("/", { replace: true });
        authCtxt.logout();
      }, 3000);
    } else {
      setErrorMessage(msg);
    }
  };

  //--------------------------------------
  // キャンセル処理の実行
  //--------------------------------------
  const onCancel = () => {
    navigate(-1);
  };

  return (
    <BasicTemplate>
      <>
        <ChangePasswordPanel
          changePasswordRequested={changePasswordRequested}
          showErrorRequested={(msg) => setErrorMessage(msg)}
          onCancel={onCancel}
        />
      </>
    </BasicTemplate>
  );
}

export default ChangePasswordPage;
