import { NameID } from "common/types/NameID";
import { PayFacilityData, PayFacilitySummary, PayFacilityUsage } from "../types/PayFacilityData";
import { PayFacilitiesMeta, PayFacilityMeta } from "../types/PayFacilityMeta";

export const usePayFacilityData = () => {
  const createEmptyData = (facility: NameID): PayFacilityData => {
    return { facility, usages: [] };
  };

  //--------------------------------------
  // 空の有料施設データを作成する
  //--------------------------------------
  const createEmptyPayFacilityUsage = (): PayFacilityUsage => {
    return {
      usageType: null,
      periodType: null,
      exemptionType: null,
      paymentType: null,
      count: 1,
      numUser: 1,
      fee: 0,
      note: "",
    };
  };

  const createDeletedFaclity = (facility: NameID): PayFacilityMeta => {
    return {
      facility,
      usageTypes: [],
      periodTypes: [],
      exemptionTypes: [],
      dayTypes: [],
      fees: [],
      isDeleted: true,
    };
  };

  const updateMeta = (meta: PayFacilitiesMeta, details?: PayFacilityData[]): PayFacilitiesMeta => {
    // 削除されている施設を探す
    const deleteds = details?.filter(
      (det) => !meta.facilities.find((fac) => fac.facility.id === det.facility.id)
    );

    // 前回の削除された施設があれば取り除き、削除されている施設があれば追加する
    return {
      ...meta,
      facilities: [
        ...meta.facilities.filter((x) => !x.isDeleted),
        ...(deleteds?.map((del) => createDeletedFaclity(del.facility)) ?? []),
      ],
    };
  };

  //--------------------------------------
  // カテゴリ一覧から空のサマリーデータを作成する
  // サマリーには合計の行が追加される
  //--------------------------------------
  const createEmptySummaries = (categories: PayFacilityMeta[]) => {
    // 施設しかないので、合計の行を追加する
    var categoryWithTotal: PayFacilityMeta[] = [...categories];
    categoryWithTotal.push({
      facility: { id: -1, name: "合計" },
      usageTypes: [],
      periodTypes: [],
      exemptionTypes: [],
      dayTypes: [],
      fees: [],
    });
    const emptySumamries: PayFacilitySummary[] = categoryWithTotal.map((x) => ({
      facility: x.facility,
      count: 0,
      numUser: 0,
      fee: 0,
    }));
    return emptySumamries;
  };

  const TOTAL = -1;
  //--------------------------------------
  // サマリーの再計算を行う
  //--------------------------------------
  const updateSummaries = (summaries: PayFacilitySummary[], facilitiesData: PayFacilityData[]) => {
    const total = summaries.find((x) => x.facility.id === TOTAL);
    if (!total) {
      console.log("PayFacility summary's total is missing");
      return;
    }
    total.count = 0;
    total.numUser = 0;
    total.fee = 0;

    summaries.forEach((facility) => {
      const targetId = facility.facility.id;
      if (targetId >= 0) {
        const details = facilitiesData.find((x) => x.facility.id === targetId);
        const targetSummary = summaries.find((x) => x.facility.id === targetId);
        if (targetSummary) {
          updateASummary(targetSummary, details?.usages);
          total.count += targetSummary.count;
          total.numUser += targetSummary.numUser;
          total.fee += targetSummary.fee;
        }
      }
    });
    return [...summaries];
  };

  //--------------------------------------
  // １つの施設のサマリーの再計算を行う
  //--------------------------------------
  const updateASummary = (summary: PayFacilitySummary, usages?: PayFacilityUsage[]) => {
    summary.count = 0;
    summary.numUser = 0;
    summary.fee = 0;
    if (!usages) {
      return;
    }

    usages.forEach((x) => {
      summary.count += x.count;
      summary.numUser += x.numUser;
      summary.fee += x.fee;
    });
  };

  return {
    createEmptyData,
    createEmptyPayFacilityUsage,
    updateMeta,
    createEmptySummaries,
    updateSummaries,
  };
};
