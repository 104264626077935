import { Col, Row, Table } from "react-bootstrap";
import { IncidentTotal } from "../types/IncidentReport";
import { INCIDENT_TYPE } from "../types/IncidetnTypes";

export const IncidentTotalTable = (props: { data: IncidentTotal[] }) => {
  const { data } = props;
  return (
    <>
      <h4>合計件数</h4>
      <Row>
        <Col xs={12} md={3}>
          <Table bordered hover responsive className="summary-table">
            <thead>
              <tr className="text-center">
                <th className="col-md-2">通報種別</th>
                <th className="col-md-1">合計</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((x) => {
                  return (
                    <tr
                      key={`total_${x.nameID.id}`}
                      className={`text-end ${x.nameID.id === INCIDENT_TYPE.TOTAL && "row-total"}`}
                    >
                      <td className="text-start">{x.nameID.name}</td>
                      <td>{x.value}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};
