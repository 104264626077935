import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Form } from "react-bootstrap";

import AuthContext from "common/store/AuthContext";

import { ILCalendarDlg } from "common/components/organisms/ILCalendarDlg";
import PrimaryButton from "common/components/atoms/PrimaryButton";

import { addMonths, getFiscalYear, daysInMonth, getFiscalYearDates } from "utils/DateTools";

import { SelectStartAndEndDayControl } from "../molecules/SelectStartAndEndDayControl";
import { SelectFiscalYearControl } from "../molecules/SelectFiscalYearControl";
import { SelectYearMonthControl } from "../molecules/SelectYearMonthControl";

enum PERIOD {
  BY_DAY,
  BY_MONTH,
  BY_YEAR,
}

type Props = {
  printRequested: (startDate: Date, endDate: Date) => Promise<void>;
  disabled: boolean;
  showByDay?: boolean;
  showByMonth?: boolean;
  showByYear?: boolean;
};

export const SelectDayRangeControl = (props: Props) => {
  const {
    printRequested,
    disabled,
    showByDay = true,
    showByMonth = true,
    showByYear = true,
  } = props;

  const [startDate, setStartDate] = useState(addMonths(new Date(), -1));
  const { selectedPark } = useContext(AuthContext);
  const [endDate, setEndDate] = useState(new Date());

  const [year, setYear] = useState(2024); // 月度・年度選択時に使用
  const [periodType, setPeriodType] = useState(PERIOD.BY_DAY);

  const [showCalendarStart, setShowCalendarStart] = useState(false);
  const [showCalendarEnd, setShowCalendarEnd] = useState(false);

  const startDateSelected = (newDate: Date) => {
    setStartDate(newDate);
    if (endDate < newDate) setEndDate(newDate);
    setShowCalendarStart(false);
  };

  const endDateSelected = (newDate: Date) => {
    setEndDate(newDate);
    if (startDate > newDate) setStartDate(newDate);
    setShowCalendarEnd(false);
  };

  const onClick = () => {
    if (!selectedPark) return;

    let from = startDate;
    let to = endDate;
    switch (periodType) {
      case PERIOD.BY_MONTH:
        {
          const monthBegin = new Date(endDate);
          monthBegin.setDate(1);
          from = monthBegin;
          const monthEnd = new Date(endDate);
          monthEnd.setDate(daysInMonth(monthEnd));
          to = monthEnd;
        }
        break;
      case PERIOD.BY_YEAR:
        {
          const { yearFrom, yearTo } = getFiscalYearDates(
            year,
            selectedPark.startMonth,
            selectedPark.startDay
          );
          from = yearFrom;
          to = yearTo;
        }
        break;
    }

    printRequested(from, to);
  };

  useEffect(() => {
    setYear(getFiscalYear(endDate, selectedPark?.startMonth ?? 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SRadioDiv>
        {showByDay && (
          <Form.Check
            inline
            label="日付指定"
            name="group1"
            type="radio"
            value={PERIOD.BY_DAY}
            checked={periodType === PERIOD.BY_DAY}
            onChange={() => setPeriodType(PERIOD.BY_DAY)}
          />
        )}
        {showByMonth && (
          <Form.Check
            inline
            label="月度指定"
            name="group1"
            type="radio"
            value={PERIOD.BY_MONTH}
            checked={periodType === PERIOD.BY_MONTH}
            onChange={() => setPeriodType(PERIOD.BY_MONTH)}
          />
        )}
        {showByYear && (
          <Form.Check
            inline
            label="年度指定"
            name="group1"
            type="radio"
            value={PERIOD.BY_YEAR}
            checked={periodType === PERIOD.BY_YEAR}
            onChange={() => setPeriodType(PERIOD.BY_YEAR)}
          />
        )}
      </SRadioDiv>
      {/* 日付指定 */}
      {periodType === PERIOD.BY_DAY && (
        <SelectStartAndEndDayControl
          startDate={startDate}
          endDate={endDate}
          startDateChanged={setStartDate}
          endDateChanged={setEndDate}
        />
      )}
      {/* 月度指定 */}
      {periodType === PERIOD.BY_MONTH && (
        <SelectYearMonthControl
          year={endDate.getFullYear()}
          month={endDate.getMonth() + 1}
          yearChanged={(newValue) => {
            const newDate = new Date(endDate);
            newDate.setFullYear(newValue);
            setEndDate(newDate);
          }}
          monthChanged={(newValue) => {
            const newDate = new Date(endDate);
            newDate.setMonth(newValue - 1);
            setEndDate(newDate);
          }}
        />
      )}
      {/* 年度指定 */}
      {periodType === PERIOD.BY_YEAR && (
        <SelectFiscalYearControl year={year} yearChanged={setYear} />
      )}
      {/* 出力ボタン */}
      <PrimaryButton
        title="出力"
        size="sm"
        onClick={onClick}
        className="mt-2"
        disabled={disabled}
      />
      {/* カレンダー開始 */}
      {showCalendarStart && (
        <ILCalendarDlg
          show={showCalendarStart}
          initialValue={startDate}
          onOK={startDateSelected}
          onCancel={() => setShowCalendarStart(false)}
        />
      )}
      {/* カレンダー終了 */}
      {showCalendarEnd && (
        <ILCalendarDlg
          show={showCalendarEnd}
          initialValue={endDate}
          onOK={endDateSelected}
          onCancel={() => setShowCalendarEnd(false)}
        />
      )}
    </>
  );
};

const SRadioDiv = styled.div`
  margin-top: 0.5rem;
`;
