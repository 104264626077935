export enum InquiryTableDefs {
  Category = "要求種別",
  Method = "受付種別",
  Recipient = "受付者",
  Contents = "内容",
  Action = "対応内容",
  NumIncident = "件数",
  Completed = "完了",
  Reported = "報告",
  Areas = "地区",
}
