import { useContext, useEffect, useState } from "react";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";
import { DashboardTemplate } from "common/components/templates/DashboardTemplate";
import FormContainer from "common/components/templates/FormContainer";
import { BaseForm } from "common/components/templates/BaseForm";
import { Form } from "react-bootstrap";

import { usePrintTemplate } from "./hooks/usePrintTemplate";
import { NameID } from "common/types/NameID";
import { PrintTemplateControl } from "./components/PrintTemplateControl";
import { ShowEmpty } from "common/components/atoms/ShowEmpty";

let isInitializing = false;

//=====================================
// 帳票登録ページ
//=====================================
export const PrintTemplatePage = (props: { pageTitle: string }) => {
  const { selectedPark } = useContext(AuthContext);
  const { setIsLoading, setLoadingMessage, setErrorMessage } = useContext(StatusContext);
  const { getAvailabePrintTypes } = usePrintTemplate();

  const [printTypes, setPrintTypes] = useState<NameID[]>([]);

  //-------------------------------------
  // 帳票登録ページ
  //-------------------------------------
  const loadPrintTypes = async () => {
    if (!selectedPark) {
      setErrorMessage("公園が設定されていません");
      return null;
    }
    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("帳票テンプレート一覧の取得中");

    const { succeeded, msg, data } = await getAvailabePrintTypes(selectedPark.parkId);

    setIsLoading(false);

    if (succeeded) {
      setPrintTypes(data ?? []);
    } else {
      setErrorMessage(msg);
      return null;
    }
  };

  useEffect(() => {
    document.title = props.pageTitle;
    const f = async () => {
      if (isInitializing) return;

      isInitializing = true;
      await loadPrintTypes();
      isInitializing = false;
    };
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardTemplate>
      <FormContainer size="max">
        {!selectedPark && <div> 公園が選択されていません </div>}

        {/* タイトル */}
        <Form className="mb-1 pb-1">
          <div className="mt-3 mb-1 ms-2 d-flex align-items-center" style={{ minWidth: "200px" }}>
            <span className="h3">
              {selectedPark?.parkName} : {props.pageTitle}{" "}
            </span>
          </div>
        </Form>
        <BaseForm className="p-3">
          {printTypes.length === 0 ? (
            <ShowEmpty>帳票の設定がありません</ShowEmpty>
          ) : (
            printTypes.map((x) => (
              <PrintTemplateControl key={`print_${x.id}`} parkInfo={selectedPark!} printType={x} />
            ))
          )}
        </BaseForm>
      </FormContainer>
    </DashboardTemplate>
  );
};
