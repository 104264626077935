import { useCallback, useContext } from "react";
import axios from "axios";

import AuthContext from "common/store/AuthContext";
import { generateErrorMsg, genApiDatePath, getServerUrl } from "utils/commonTools";
import { REPORT_TYPE } from "common/types/consts/Defines";

export const useCommon = () => {
  const { token } = useContext(AuthContext);

  //--------------------------------------
  // 日報登録済み日付の一覧を取得する
  //--------------------------------------
  const getReportDays = useCallback(
    async (
      parkId: number,
      date: Date,
      reportType: number
    ): Promise<{ succeeded: boolean; msg: string; data?: Date[] }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };

      try {
        const { data } = await axios.get(
          getServerUrl() +
            `common/report/days/${parkId}/${date.getFullYear()}/${
              date.getMonth() + 1
            }/${reportType}`,
          config
        );
        const dateStr = data as string[];
        const dates = dateStr.map((x) => new Date(x as string));
        return {
          succeeded: true,
          msg: "",
          data: dates,
        };
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    [token]
  );

  //--------------------------------------
  // 指定した日の登録済みページのタブID一覧を取得する
  //--------------------------------------
  const getReportedTabs = useCallback(async (parkId: number, reportType: REPORT_TYPE, date: Date)
  : Promise<{ succeeded: boolean; msg: string; data?: number[] }>=> {
    const config = {
      headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
    };

    try {
      const { data } = await axios.get(
        getServerUrl() + `common/report/tabs/${parkId}/${reportType}/${genApiDatePath(date)}`,
        config
      );
      const tabIds = data as number[];
      return {
        succeeded: true,
        msg: "",
        data: tabIds,
      };
    } catch (error) {
      var errMsg = generateErrorMsg(error);
      return { succeeded: false, msg: errMsg };
    }
  }, [token])

  return {
    getReportDays, getReportedTabs
  };
};
