import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  ComposedChart,
} from "recharts";
import AuthContext from "common/store/AuthContext";
import { getFiscalYear } from "utils/DateTools";

import { MonthComparison } from "../types/AggregateData";
import { useContext } from "react";

type Props = {
  title: string;
  data: MonthComparison[];
  unit: string;
  height?: number;
  color1: string;
  color2: string;
};

//=====================================
// 月別、月別累計データのグラフ表示
//=====================================
export const ByMonthChart = (props: Props) => {
  const { title, data, unit, height = 300 } = props;
  const authCtxt = useContext(AuthContext);

  const thisYear = getFiscalYear(new Date(), authCtxt.selectedPark?.startMonth ?? 1);

  return (
    <div className="mt-2 mx-2 chart-border">
      <span className="h5 d-flex justify-content-center">{title}</span>

      <ResponsiveContainer width={"100%"} height={height}>
        <ComposedChart width={600} height={300} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tickLine={false} dataKey="monthString" tick={{ fontSize: 12 }} />
          <YAxis
            yAxisId={1}
            tickCount={6}
            label={{ value: unit, fontSize: 12 }}
            tick={{ fontSize: 12 }}
          />
          <YAxis
            yAxisId={2}
            tickCount={6}
            label={{ value: "前年比", fontSize: 12 }}
            tick={{ fontSize: 12 }}
            orientation="right"
          />
          {/* ツールチップで前年比には＋-の符号をつけたい */}
          <Tooltip
            formatter={(value, name, props) => {
              if (props.dataKey === "difference") {
                if (isNaN(+value) || !isFinite(+value)) return "-";
                if (+value > 0) return `+${(+value).toFixed(1)}%`;
                else return `${(+value).toFixed(1)}%`;
              }
              return `${value} ${unit}`;
            }}
          />
          <Legend />
          <Bar yAxisId={1} dataKey="prevYear" name={`${thisYear - 1}年度`} fill={props.color1} />
          <Bar yAxisId={1} dataKey="thisYear" name={`${thisYear}年度`} fill={props.color2} />
          <Line yAxisId={2} type="monotone" dataKey="difference" name={`前年比`} stroke="#f84" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
