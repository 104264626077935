import React, { useState, useContext, useEffect } from "react";
import { Form } from "react-bootstrap";

import { DashboardTemplate } from "common/components/templates/DashboardTemplate";
import FormContainer from "common/components/templates/FormContainer";
import { BaseForm } from "common/components/templates/BaseForm";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";

import { LastUpdatedInfo } from "common/types/LastUpdatedInfo";
import { REPORT_TYPE, TAB_ID } from "common/types/consts/Defines";

import { createDocumentTitle } from "utils/commonTools";

import { useComments } from "./hooks/useComments";
import { PageTop } from "common/components/organisms/PageTop";
import MyTextArea from "common/components/atoms/MyTextArae";

type Props = {
  pageTitle: string;
  reportType: REPORT_TYPE;
};

// ------------------------------------------------------------------
// コメントページ
//-------------------------------------------------------------------
const CommentsPage = (props: Props) => {
  const { pageTitle, reportType } = props;
  const [lastUpdated, setLastUpdated] = useState<LastUpdatedInfo | undefined>(undefined);

  const { getReportData, saveReportData } = useComments();

  const {
    currentDate,
    setIsLoading,
    setLoadingMessage,
    setErrorMessage,
    showToastMessage,
    isEditing,
  } = useContext(StatusContext);
  const { selectedPark, readOnly } = useContext(AuthContext);

  const [comment, setComment] = useState<string>("");

  //--------------------------------------
  // 日報データをサーバーから取得する
  // メタデータを取得しないため、他のタブとは異なる
  //--------------------------------------
  const loadReportData = async () => {
    setErrorMessage("");

    if (selectedPark == null) {
      setErrorMessage("公園が選択されていません");
      return;
    }

    setIsLoading(true);
    setLoadingMessage("日報データの取得中");

    const { succeeded, msg, data, lastUpdated } = await getReportData(
      selectedPark.parkId,
      reportType,
      currentDate
    );
    setIsLoading(false);
    if (succeeded) {
      setLastUpdated(
        lastUpdated ? { ...lastUpdated, savedByApp: lastUpdated?.savedByApp || false } : undefined
      );
      setComment(data?.comment ?? "");
    } else {
      setErrorMessage(msg);
    }
  };

  //--------------------------------------
  // 日報の登録処理
  //--------------------------------------
  const saveData = async (): Promise<boolean> => {
    setErrorMessage("");

    if (!selectedPark) return false;

    setIsLoading(true);
    setLoadingMessage("日報の更新中");

    const { succeeded, msg, lastUpdated } = await saveReportData(
      selectedPark.parkId,
      reportType,
      currentDate,
      {
        comment,
      },
      pageTitle
    );

    setIsLoading(false);
    if (succeeded) {
      if (lastUpdated) {
        setLastUpdated({ ...lastUpdated, savedByApp: false });
      }

      showToastMessage("日報の登録", msg);
      return true;
    } else {
      setErrorMessage("日報の保存に失敗しました。" + msg!);
      return false;
    }
  };

  //--------------------------------------
  // サブミット処理
  //--------------------------------------
  const onSave = async () => {
    await saveData();
  };

  //--------------------------------------
  // 初回又は日付が変わった時に実行されるコード
  //--------------------------------------
  useEffect(() => {
    document.title = createDocumentTitle(pageTitle);
    const f = async () => {
      await loadReportData();
    };
    f();
  }, [currentDate, reportType]); // eslint-disable-line react-hooks/exhaustive-deps

  //--------------------------------------
  // レンダリング
  //--------------------------------------
  return (
    <>
      <DashboardTemplate>
        <FormContainer size="max">
          {!selectedPark ? (
            <div> 公園が選択されていません </div>
          ) : (
            <>
              <PageTop
                title={pageTitle}
                reportType={reportType}
                tabId={TAB_ID.SPECIAL_NOTE}
                lastUpdated={lastUpdated}
                onEditStart={async () => await loadReportData()}
                onSave={!readOnly ? onSave : undefined}
                onCancel={async () => await loadReportData()}
              />
              <BaseForm>
                <Form.Label htmlFor="txtNotes" className="h5">
                  コメント
                </Form.Label>
                <MyTextArea
                  id="txtNotes"
                  readonly={!isEditing}
                  initialValue={comment}
                  changed={(value) => {
                    setComment(value);
                  }}
                  style={{ height: "200px" }}
                />
              </BaseForm>
            </>
          )}
        </FormContainer>
      </DashboardTemplate>
    </>
  );
};

export default CommentsPage;
