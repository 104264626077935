import { useContext, useEffect, useState } from "react";
import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";

import { DashboardTemplate } from "common/components/templates/DashboardTemplate";
import { useStaffMaintenance } from "./hooks/useStaffMaintenance";

import { StaffMasterData } from "./types/StaffMasterInfo";
import { NameID } from "common/types/NameID";
import { createDocumentTitle } from "utils/commonTools";
import { StaffListPanel } from "./components/StaffListPanel";
import FormContainer from "common/components/templates/FormContainer";
import { BaseForm } from "common/components/templates/BaseForm";
import { Col, Form } from "react-bootstrap";

let isInitializing = false;

export const StaffMaintenancePage = (props: { pageTitle: string }) => {
  const { selectedPark } = useContext(AuthContext);
  const { setIsLoading, setLoadingMessage, setErrorMessage } = useContext(StatusContext);

  const { getMasterInfo, updateMasterData } = useStaffMaintenance();
  const [staffs, setStaffs] = useState<StaffMasterData[]>([]);
  const [posts, setPosts] = useState<NameID[]>([]);

  //--------------------------------------
  // 初期化処理： メタデータ取得 → 日報データ取得
  //--------------------------------------
  const initialize = async () => {
    if (!selectedPark) {
      setErrorMessage("公園が設定されていません");
      return null;
    }

    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("メタデータの取得中");

    const { succeeded, msg, data } = await getMasterInfo(selectedPark.parkId);
    setIsLoading(false);
    if (succeeded) {
      setStaffs(data?.staffs ?? []);
      setPosts(data?.posts ?? []);
    } else {
      setErrorMessage(msg ?? "");
    }
  };

  const onUpdate = async (info: StaffMasterData): Promise<number> => {
    if (!selectedPark) return -1;

    setErrorMessage("");

    setIsLoading(true);
    setLoadingMessage("データ登録中");

    const { succeeded, data, msg } = await updateMasterData(selectedPark.parkId, info);

    setIsLoading(false);
    if (!succeeded || !data) {
      setErrorMessage(msg);
      return -1;
    }
    return data.employeeID;
  };

  useEffect(() => {
    document.title = createDocumentTitle(props.pageTitle);
    const f = async () => {
      if (isInitializing) return;

      isInitializing = true;
      await initialize();
      isInitializing = false;
    };
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPark]);

  return (
    <DashboardTemplate>
      <FormContainer size="max">
        {!selectedPark ? (
          <div> 公園が選択されていません </div>
        ) : !selectedPark.isAdmin ? (
          <div>管理者以外は利用できません</div>
        ) : (
          <>
            <Form className="mb-1 pb-1">
              <div
                className="mt-3 mb-1 ms-2 d-flex align-items-center"
                style={{ minWidth: "200px" }}
              >
                <span className="h3">
                  {selectedPark?.parkName} : {props.pageTitle}{" "}
                </span>
              </div>
            </Form>

            <BaseForm className="p-3">
              <Col style={{ maxWidth: "700px" }}>
                <StaffListPanel staffs={staffs} posts={posts} updated={onUpdate} />
              </Col>
            </BaseForm>
          </>
        )}
      </FormContainer>
    </DashboardTemplate>
  );
};
