import { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";
import { createDocumentTitle, getDateString } from "utils/commonTools";
import { getFiscalYear } from "utils/DateTools";

import { DashboardTemplate } from "common/components/templates/DashboardTemplate";
import FormContainer from "common/components/templates/FormContainer";
import { BaseForm } from "common/components/templates/BaseForm";
import { TAB_ID } from "common/types/consts/Defines";

import { useAggregate } from "./hooks/useAggregate";
import { useChartData } from "./hooks/useChartData";
import { useSettings } from "./hooks/useSettings";

import { HolidayBadge } from "common/components/atoms/HolidayBadge";
import { SelectGraphsDlg } from "./components/SelectGraphsDlg";
import { CHART_TYPES, ChartTypes } from "./types/ChartTypes";
import { UserSettings } from "./types/UserSettings";
import { ShowEmpty } from "common/components/atoms/ShowEmpty";
import { ByMonth } from "./types/AggregateData";
import { ChartInfo } from "./types/ChartInfo";
import { ByMonthChart } from "./components/ByMonthChart";
import { InquiryTable } from "./components/InquiryTable";
import { VisitorsTable } from "./components/VisitorsTable";
import { addDays } from "utils/DateTools";
import { ByMonthTable } from "./components/ByMonthTable";
import FunctionButton from "common/components/atoms/FunctionButton";

let isInitializing = false;

//=====================================
// ホームページ
//=====================================
const HomePage = (props: { pageTitle: string }) => {
  const pageTitle = props.pageTitle;
  const { selectedPark } = useContext(AuthContext);
  const { setIsLoading, setLoadingMessage, setErrorMessage } = useContext(StatusContext);

  const { getAggregateData } = useAggregate();
  const { getUserSettings, saveUserSettings, createEmptySettings } = useSettings();
  const {
    getChartTitle,
    extractVisitorsByMonth,
    extractVisitorDetailsByMonth,
    extractInquiriesByMonth,
  } = useChartData();

  const [settings, setSettings] = useState<UserSettings>(createEmptySettings());
  const [showSelGraphs, setShowSelGraphs] = useState(false);

  const [byMonthData, setByMonthData] = useState<ByMonth[]>([]);

  //--------------------------------------
  // ユーザー設定をロードする
  //--------------------------------------
  const loadUserSettings = async () => {
    if (!selectedPark) {
      setErrorMessage("公園が設定されていません");
      return null;
    }
    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("ユーザー設定の取得中");

    const { succeeded, msg, data } = await getUserSettings();

    setIsLoading(false);

    if (succeeded) {
      if (data) setSettings(data);
      return data;
    } else {
      setErrorMessage(msg);
      return null;
    }
  };

  //--------------------------------------
  // ユーザー設定を保存する
  //--------------------------------------
  const execUpdateUserSettings = async (newSettings: UserSettings) => {
    if (!selectedPark) {
      setErrorMessage("公園が設定されていません");
      return null;
    }

    setErrorMessage("");
    const { succeeded, msg } = await saveUserSettings(newSettings);

    // 失敗した場合のみ。成功は通知なし
    if (!succeeded) {
      setErrorMessage(msg ?? "不明なエラーが発生しました");
      return null;
    }
  };

  //--------------------------------------
  // 初期化処理： 集計データを取得する
  //--------------------------------------
  const loadAggregate = async (settings: UserSettings) => {
    if (!selectedPark) {
      setErrorMessage("公園が設定されていません");
      return null;
    }

    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("集計データの取得中");

    const to = new Date();
    const fisicalYear = getFiscalYear(to, selectedPark.startMonth);
    const from = new Date(fisicalYear - 1, selectedPark.startMonth - 1, selectedPark.startDay);

    const allTabIds = settings.charts.map(
      (x) => ChartTypes.find((y) => y.id === x.chartId)?.tabId ?? TAB_ID.INVALID
    );
    const tabIds = [...new Set(allTabIds)];
    const { succeeded, msg, data } = await getAggregateData(selectedPark.parkId, from, to, tabIds);

    setIsLoading(false);

    if (succeeded) {
      setByMonthData(data!);
    } else {
      setErrorMessage(msg);
      return null;
    }
  };

  useEffect(() => {
    document.title = createDocumentTitle(pageTitle);
    const f = async () => {
      if (isInitializing) return;

      isInitializing = true;
      const usrSetting = await loadUserSettings();
      if (usrSetting && usrSetting.charts.length > 0) {
        await loadAggregate(usrSetting);
      }
      isInitializing = false;
    };
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ROW_HEIGHT = 300;
  const startMonth = selectedPark?.startMonth ?? 4;
  const dailyTabs = selectedPark?.dailyTabs;
  const createChart = (chartInfo: ChartInfo) => {
    switch (chartInfo.chartId) {
      case CHART_TYPES.CHART_VISITORS_MONTHLY:
        return (
          <ByMonthChart
            title={getChartTitle(chartInfo.chartId, dailyTabs) ?? "月別利用者数"}
            data={extractVisitorsByMonth(startMonth, byMonthData, false)}
            unit="人"
            height={chartInfo.numRows * ROW_HEIGHT}
            color1="#f98866"
            color2="#ff420e"
          />
        );
      case CHART_TYPES.CHART_VISITORS_ACCUMULATE:
        return (
          <ByMonthChart
            title={`${
              getChartTitle(chartInfo.chartId, dailyTabs) ?? "累計利用者数"
            }（前年度との比較）`}
            data={extractVisitorsByMonth(startMonth, byMonthData, true)}
            unit="人"
            height={chartInfo.numRows * ROW_HEIGHT}
            color1="#80bd9e"
            color2="#89da59"
          />
        );
      case CHART_TYPES.CHART_INQUIRY_MONTHLY:
        return (
          <ByMonthChart
            title={getChartTitle(chartInfo.chartId, dailyTabs) ?? "問合苦情要望件数"}
            data={extractInquiriesByMonth(startMonth, byMonthData, false)}
            unit="件"
            height={chartInfo.numRows * ROW_HEIGHT}
            color1="#90afc5"
            color2="#336b87"
          />
        );
      case CHART_TYPES.CHART_INQUIRY_ACCUMULATE:
        return (
          <ByMonthChart
            title={getChartTitle(chartInfo.chartId, dailyTabs) ?? "累計問合苦情要望件数"}
            data={extractInquiriesByMonth(startMonth, byMonthData, true)}
            unit="件"
            height={chartInfo.numRows * ROW_HEIGHT}
            color1="#2a3132"
            color2="#763626"
          />
        );
      case CHART_TYPES.TABLE_VISITORS_TODAY:
        return (
          <VisitorsTable
            title={getChartTitle(chartInfo.chartId, dailyTabs) ?? "本日利用者数"}
            date={new Date()}
          />
        );
      case CHART_TYPES.TABLE_VISITORS_YESTERDAY:
        return (
          <VisitorsTable
            title={getChartTitle(chartInfo.chartId, dailyTabs) ?? "昨日の利用者数"}
            date={addDays(new Date(), -1)}
          />
        );
      case CHART_TYPES.TABLE_VISITORS_MONTHLY:
        return (
          <ByMonthTable
            title={getChartTitle(chartInfo.chartId, dailyTabs) ?? "月別利用者数詳細"}
            data={extractVisitorDetailsByMonth(startMonth, byMonthData)}
            height={chartInfo.numRows * ROW_HEIGHT}
          />
        );
      case CHART_TYPES.TABLE_INQUIRY_TODAY:
        return (
          <InquiryTable
            title={getChartTitle(chartInfo.chartId, dailyTabs) ?? "本日の問合苦情要望"}
            date={new Date()}
          />
        );
      case CHART_TYPES.TABLE_INQUIRY_YESTERDAY:
        return (
          <InquiryTable
            title={getChartTitle(chartInfo.chartId, dailyTabs) ?? "昨日の問合苦情要望"}
            date={addDays(new Date(), -1)}
          />
        );
      default:
        return (
          <div style={{ textAlign: "center", margin: "1rem 0" }}>
            <ShowEmpty>未実装のグラフ・表です</ShowEmpty>
          </div>
        );
    }
  };

  return (
    <>
      <DashboardTemplate>
        <FormContainer size="max">
          {!selectedPark && <div> 公園が選択されていません </div>}

          {/* タイトル */}
          <SForm
            className="d-flex sticky-top"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <div className="mt-3 mb-1 ms-2" style={{ minWidth: "200px" }}>
              <span className="h3">
                {selectedPark?.parkName} : {getDateString(new Date())}
              </span>
              <HolidayBadge date={new Date()} />
            </div>
            <FunctionButton
              tooltip="表示する項目を選択する"
              className="btn btn-info"
              size="md"
              onClick={() => {
                setShowSelGraphs(true);
              }}
            >
              {<i className="fas fa-gear" />}
            </FunctionButton>
          </SForm>

          {/* チャート一覧 */}
          <BaseForm>
            {settings.charts.length === 0 ? (
              <div className="my-2 mx-2">
                <ShowEmpty>右上の"編集ボタンで"グラフの設定をしてください。</ShowEmpty>
              </div>
            ) : (
              <Row style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>
                {!byMonthData ? (
                  <ShowEmpty>データがありません</ShowEmpty>
                ) : (
                  settings.charts.map((chart, index) => {
                    return (
                      <Col md={chart.numCols * 6} key={`chart_${index}`}>
                        {createChart(chart)}
                      </Col>
                    );
                  })
                )}
              </Row>
            )}
          </BaseForm>

          {/* グラフ選択ダイアログ */}
          {showSelGraphs && (
            <SelectGraphsDlg
              candidates={ChartTypes}
              charts={settings.charts}
              onOK={async (newCharts) => {
                const newSettings = { ...settings, charts: newCharts } as UserSettings;
                setSettings(newSettings);
                await execUpdateUserSettings(newSettings);
                await loadAggregate(newSettings);
                setShowSelGraphs(false);
              }}
              onCancel={() => setShowSelGraphs(false)}
            />
          )}
        </FormContainer>
      </DashboardTemplate>
    </>
  );
};

export default HomePage;

const SForm = styled(Form)`
  background-color: #ffffff;
  margin-top: 5px;
  padding: 0.5rem 0rem;
`;
