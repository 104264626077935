//--------------------------------------
// 自主事業、住民協働事業で変わる部分をまとめたもの

import { EVENT_TYPE } from "./EVENT_TYPE";

//--------------------------------------
export interface ParkEventSpecific {
  EventType: EVENT_TYPE;
  Title: string;
}

const IndependentEvent: ParkEventSpecific = {
  EventType: EVENT_TYPE.Independent,
  Title: "自主事業",
};

const CollaborateEvent: ParkEventSpecific = {
  EventType: EVENT_TYPE.Collaborate,
  Title: "住民協働事業",
};

export { IndependentEvent, CollaborateEvent };
