import MyNumberInput from "common/components/atoms/MyNumberInput";
import { Form } from "react-bootstrap";
import styled from "styled-components";

type Props = {
  year: number;
  month: number;
  yearChanged: (newValue: number) => void;
  monthChanged: (newValue: number) => void;
};

export const SelectYearMonthControl = ({ year, month, yearChanged, monthChanged }: Props) => {
  return (
    <SSelectRootDiv>
      <MyNumberInput
        initialValue={year}
        min={2000}
        max={new Date().getFullYear()}
        changed={(newValue) => {
          yearChanged(newValue);
        }}
        style={{ lineHeight: "1.5", width: "100px" }}
      />
      <Form.Label>年</Form.Label>
      <Form.Select
        value={month}
        onChange={(e) => {
          monthChanged(Number(e.currentTarget.value));
        }}
        style={{ width: "100px" }}
      >
        {[...Array(12)].map((_, idx) => (
          <option key={`month_${idx}`} value={idx + 1}>
            {idx + 1}
          </option>
        ))}
      </Form.Select>
      <Form.Label>月</Form.Label>
    </SSelectRootDiv>
  );
};

const SSelectRootDiv = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  direction: col;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: baseline;
`;
