/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useCallback } from "react";
import axios from "axios";

import AuthContext from "common/store/AuthContext";
import {
  genApiDatePath,
  getServerUrl,
  generateErrorMsg,
  generateSaveResult,
} from "utils/commonTools";
import { GetBasicResponse, PostBasicResponse, PostResponse } from "common/types/responses/BasicResponse";
import { LastUpdatedInfo } from "common/types/LastUpdatedInfo";

import { VisitorsMetaData } from "../types/VisitortsMetaData";
import { VisitorReport } from "../types/VisitorReport";
import { useVisitorData } from "./useVisitorData";

export const useVisitors = () => {
  const authCtxt = useContext(AuthContext);
  const { token } = authCtxt;

  const { createData } = useVisitorData();

  //--------------------------------------
  // 来園者数のメタデータをサーバーから取得する
  //--------------------------------------
  const loadMetaData = useCallback(
    async (parkId: number): Promise<{ succeeded: boolean; msg: string; data?: VisitorReport }> => {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const { data } = await axios.get(getServerUrl() + `visitors/meta/${parkId}`, config);
        const visitMeta = data as GetBasicResponse<VisitorsMetaData>;

        const metaData = {
          places: visitMeta.data.places?.map((visitor) => createData(visitor, visitMeta.data.timePeriods)),
          methods: visitMeta.data.methods?.map((visitor) => createData(visitor, visitMeta.data.timePeriods)),
          others: visitMeta.data.others?.map((visitor) => createData(visitor, visitMeta.data.timePeriodOthers)),
        } as VisitorReport;

        return { succeeded: true, msg: "", data: metaData };
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    []
  );

  //--------------------------------------
  // 日報データをサーバーから取得する
  //--------------------------------------
  const getReportData = useCallback(
    async (
      parkId: number,
      date: Date
    ): Promise<{
      succeeded: boolean;
      msg: string;
      data?: VisitorReport;
      lastUpdated?: LastUpdatedInfo;
    }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };

      try {
        const { data } = await axios.get(
          getServerUrl() + `visitors/report/${parkId}/${genApiDatePath(date)}`,
          config
        );

        const reportData = data as PostBasicResponse<VisitorReport>;
        if (!reportData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }
        authCtxt.updateToken(reportData.token, new Date(reportData.expiresAt));

        return {
          succeeded: true,
          msg: "",
          data: reportData.data,
          lastUpdated: reportData.lastUpdated,
        };
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    []
  );

  const saveReportData = useCallback(
    async (
      parkId: number,
      date: Date,
      reportData: VisitorReport,
      title: string,
    ): Promise<{ succeeded: boolean; msg: string; lastUpdated?: LastUpdatedInfo }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };

      try {
        const { data, status } = await axios.post(
          getServerUrl() + `visitors/report/${parkId}/${genApiDatePath(date)}`,
          reportData,
          config
        );
        const responseData = data as PostResponse;
        if (!responseData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }
        authCtxt.updateToken(responseData.token, new Date(responseData.expiresAt));

        return generateSaveResult(title, status, data as PostResponse);
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    []
  );

  return {
    loadMetaData,
    getReportData,
    saveReportData,
  };
};
