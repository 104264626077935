import { useState, useEffect, memo } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

type Props = {
  className?: string;
  initialValue: boolean;
  changed?: (changed: boolean) => void;
  readonly?: boolean;
  label?: string;
  borderAlways?: boolean;
};

export const MyCheckbox = memo(
  ({ className, initialValue, changed, readonly = false, label, borderAlways = false }: Props) => {
    const [value, setValue] = useState(false);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <SFormCheck
        className={`${className} ${borderAlways && "borderAlways"}`}
        type="checkbox"
        checked={value}
        label={label}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.checked);
          changed?.(e.target.checked);
        }}
        disabled={readonly}
      ></SFormCheck>
    );
  }
);

export default MyCheckbox;

const SFormCheck = styled(Form.Check)`
  :not(.borderAlways) {
    .form-check-input {
      &:disabled {
        border: none;
      }
    }
  }
`;
