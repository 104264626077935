import { Button } from "react-bootstrap";
import styled from "styled-components";

type Props = {
  onClick: (e: React.MouseEvent) => void;
  text?: string;
};
export const CancelButton = (props: Props) => {
  return (
    <SButton onClick={props.onClick} variant="outline-primary">
      {props.text || "キャンセル"}
    </SButton>
  );
};

const SButton = styled(Button)`
  :disabled {
    border: none;
  }
  :hover {
    background: #cef;
  }
`;
