// Generated by https://quicktype.io
export interface AggregateData {
  monthData: ByMonth[];
}

export interface ByMonth {
  year: number;
  month: number;
  visitorData?: AggVisitor;
  inquiryData?: AggInquiry[];
}

export interface AggInquiry {
  id: number;
  name: string;
  visit: number;
  phone: number;
  email: number;
  others: number;
  total: number;
}

export interface AggVisitor {
  places: TotalData[];
  methods: TotalData[];
  others: TotalData[];
}

export interface TotalData {
  id: number;
  name: string;
  value: number;
}

export class MonthComparison {
  private _month: number;
  public get month() {
    return this._month;
  }
  public set month(value: number) {
    this._month = value;
  }
  public get monthString() {
    return `${this._month}月`;
  }

  private _prevYear: number;
  public get prevYear(): number {
    return this._prevYear;
  }
  public set prevYear(value: number) {
    this._prevYear = value;
  }

  private _thisYear: number;
  public get thisYear(): number {
    return this._thisYear;
  }
  public set thisYear(value: number) {
    this._thisYear = value;
  }

  public get difference() {
    return ((this._thisYear - this._prevYear) / this._prevYear) * 100;
  }
  constructor(month: number = 0, prevYear: number = 0, thisYear: number = 0) {
    this._month = month;
    this._prevYear = prevYear;
    this._thisYear = thisYear;
  }
}

export interface DetailsByMonth {
  month: number;
  prevYear: {
    year: number;
    data: TotalData[];
  };
  thisYear: {
    year: number;
    data: TotalData[];
  };
}
