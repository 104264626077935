import { useContext, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

import StatusContext from "common/store/StatusContext";
import { usePrintTemplate } from "../hooks/usePrintTemplate";
import { NameID } from "common/types/NameID";
import { ParkInfo } from "common/types/ParkInfo";
import FunctionButton from "common/components/atoms/FunctionButton";

//=====================================
// 帳票ファイル登録行
//=====================================
export const PrintTemplateControl = (props: { parkInfo: ParkInfo; printType: NameID }) => {
  const { parkInfo, printType: info } = props;
  const [file, setFile] = useState<File | undefined>(undefined);
  const fileInput = useRef<HTMLInputElement | null>(null);

  const { setErrorMessage, showToastMessage } = useContext(StatusContext);

  const { uploadFile, downloadFile } = usePrintTemplate();

  //--------------------------------------
  // ファイルの選択処理
  //--------------------------------------
  const onFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = e.target.files;
    if (files && files[0]) {
      setFile(files[0]);
    }
  };

  //--------------------------------------
  // ファイルのアップロード処理
  //--------------------------------------
  const onFileUpload = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!file) return;

    setErrorMessage("");
    const { succeeded, msg } = await uploadFile(parkInfo.parkId, info.id, file);
    if (succeeded) {
      showToastMessage("帳票登録", `帳票"${info.name}"のアップロードが完了しました`);

      if (fileInput.current) fileInput.current.value = "";
      setFile(undefined);
    } else setErrorMessage(msg);
  };

  //--------------------------------------
  // ファイルのダウンロード処理
  //--------------------------------------
  const onFileDownload = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setErrorMessage("");
    const { succeeded, msg } = await downloadFile(
      parkInfo.parkId,
      parkInfo.parkName,
      info.id,
      info.name
    );

    if (!succeeded) {
      setErrorMessage(msg);
    } else if (msg) {
      showToastMessage("帳票ダウンロード", msg);
    }
  };

  return (
    <SRootDiv>
      <STitle>{info.name}</STitle>
      <Form.Group>
        <Form.Control
          type="file"
          ref={fileInput}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={onFileSelect}
        ></Form.Control>
      </Form.Group>
      <FunctionButton
        onClick={onFileUpload}
        disabled={file === undefined}
        tooltip="アップロード"
        buttonType="edit"
      >
        {<i className="fas fa-upload" />}
      </FunctionButton>
      <FunctionButton
        className="ms-1"
        onClick={onFileDownload}
        size="sm"
        tooltip="ダウンロード"
        buttonType="primary"
      >
        {<i className="fas fa-download" />}
      </FunctionButton>
    </SRootDiv>
  );
};

const SRootDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 0.5rem;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

const STitle = styled.span`
  margin: 0 1rem 0 0;
  min-width: 80px;
`;
