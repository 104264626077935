import { useCallback, useContext } from "react";
import axios, { HttpStatusCode } from "axios";
import AuthContext from "common/store/AuthContext";

import { generateErrorMsg, getServerUrl } from "utils/commonTools";
import { UserSettings } from "../types/UserSettings";

//=====================================
// ユーザー設定関連のhook
//=====================================
export const useSettings = () => {
  const authCtxt = useContext(AuthContext);
  const { token } = authCtxt;

  //--------------------------------------
  // ユーザー設定を取得する
  //--------------------------------------
  const getUserSettings = useCallback(async (): Promise<{
    succeeded: boolean;
    msg: string;
    data?: UserSettings;
  }> => {
    try {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };

      const { data, status } = await axios.get(getServerUrl() + `user/settings`, config);
      if (status === HttpStatusCode.NoContent) {
        return { succeeded: true, msg: "" };
      }

      const settings = data as UserSettings;
      if (!settings) {
        return { succeeded: false, msg: "取得したデータに異常がありました" };
      }

      return {
        succeeded: true,
        msg: "",
        data: settings,
      };
    } catch (error) {
      var errMsg = generateErrorMsg(error);
      return { succeeded: false, msg: errMsg };
    }
  }, [token]);

  //--------------------------------------
  // ユーザー設定を保存する
  //--------------------------------------
  const saveUserSettings = useCallback(
    async (settings: UserSettings): Promise<{ succeeded: boolean; msg?: string }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };
      try {
        const url = getServerUrl() + `user/settings`;
        const { status, data } = await axios.post(url, settings, config);

        if (status === HttpStatusCode.Ok) {
          return { succeeded: true };
        }
        return { succeeded: false, msg: data };
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    [token]
  );

  //--------------------------------------
  // 空のユーザー設定を作成する
  //--------------------------------------
  const createEmptySettings = (): UserSettings => {
    return { charts: [] };
  };

  return { getUserSettings, saveUserSettings, createEmptySettings };
};
