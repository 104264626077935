import { ReactNode } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

type Props = {
  children: ReactNode;
  className?: string;
};

export const BaseForm = (props: Props) => {
  return <SForm className={` ${props.className} mb-4 mt-2 px-4 py-4`}>{props.children}</SForm>;
};

const SForm = styled(Form)`
  background-color: #ffffff;
  border: 1px solid #0002;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
`;
