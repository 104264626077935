import { useContext } from "react";
import axios, { AxiosRequestConfig, HttpStatusCode } from "axios";
import AuthContext from "common/store/AuthContext";

import { generateErrorMsg, getServerUrl } from "utils/commonTools";
import { useGetPrint } from "./useGetPrint";
import { getPrintResult } from "../types/getPrintResult";

//--------------------------------------
// 期間イベントの印刷
//--------------------------------------
export const usePeriodicalEventReport = () => {
  const authCtxt = useContext(AuthContext);
  const { token } = authCtxt;

  const { initiateDownload } = useGetPrint();

  const getPeriodiicalEventReport = async (
    eventId: number,
    eventName: string,
    title: string, // 帳票につける名前
    recordId?: string
  ): Promise<getPrintResult> => {
    const config = {
      headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      responseType: "blob",
      params: {
        recordId,
      },
    } as AxiosRequestConfig;

    const parkId = authCtxt.selectedPark?.parkId;
    const parkName = authCtxt.selectedPark?.parkName;

    try {
      const response = await axios.get(
        getServerUrl() + `print/periodicalEvents/${parkId}/${eventId}`,
        config
      );
      if (response.status === HttpStatusCode.NoContent) {
        return {
          succeeded: false,
          msg: "レポートを生成できませんでした（データの登録が無いか、帳票が未登録です）",
        };
      }
      const url = window.URL.createObjectURL(response.data);

      const fileName = `${title}_${parkName}_${eventName}.xlsx`;
      initiateDownload(url, fileName);
      return { succeeded: true, msg: "" };
    } catch (error) {
      var errMsg = generateErrorMsg(error);
      return { succeeded: false, msg: errMsg };
    }
  };

  return { getPeriodiicalEventReport };
};
