import styled from "styled-components";
import FunctionButton from "common/components/atoms/FunctionButton";
import { ExclusiveUsesData } from "../types/ExclusiveUsesReport";
import { Table } from "react-bootstrap";
import MyCheckbox from "common/components/atoms/MyCheckbox";
import { RowEditButtons } from "common/components/molecules/RowEditButtons";
import { MultiLineText } from "common/components/atoms/MultiLineText";

type Props = {
  Details: Array<ExclusiveUsesData> | null;
  readonly: boolean;
  addDataRequested: () => void;
  editDataRequested: (data: ExclusiveUsesData, index: number) => void;
  deleteDataRequested: (data: ExclusiveUsesData) => void;
  showTitle?: boolean;
};

export const ExclusiveUsesDetailTable = (props: Props) => {
  const {
    Details,
    readonly,
    addDataRequested,
    editDataRequested,
    deleteDataRequested,
    showTitle = true,
  } = props;

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mx-2 my-2">
        {showTitle && <h4>詳細</h4>}
        {!readonly && (
          <FunctionButton
            onClick={(e) => {
              e.preventDefault();
              addDataRequested();
            }}
            className="ms-2"
            tooltip="追加"
          >
            <i className="fa-regular fa-plus"></i>
          </FunctionButton>
        )}
      </div>
      <Table bordered>
        <thead>
          <tr className="text-center">
            <Sth0>占用種別</Sth0>
            <Sth0>占用者名</Sth0>
            <th className="col-md-*">内容</th>
            <th className="col-md-*">連絡先</th>
            <th className="col-md-1">料金</th>
            <th className="col-md-1">免除</th>
            {!readonly && <th style={{ width: "100px" }}>操作</th>}
          </tr>
        </thead>
        <tbody>
          {Details &&
            Details.map((x, index) => (
              <tr key={`inq_${index}`}>
                <TdCenter>{x.usageType?.name}</TdCenter>
                <TdCenter>{x.occupantName}</TdCenter>
                <td>
                  <MultiLineText text={x.contents} />
                </td>
                <td>
                  <MultiLineText text={x.contact} />
                </td>
                <td className="text-end">{x.sales}</td>
                <TdCenter>
                  <MyCheckbox initialValue={x.exemption} readonly={true} />
                </TdCenter>
                {!readonly && (
                  <TdCenter>
                    <RowEditButtons
                      editRequested={() => editDataRequested(x, index)}
                      deleteRequested={() => deleteDataRequested(x)}
                    />
                  </TdCenter>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

const TdCenter = styled.td`
  text-align: center;
`;

const Sth0 = styled.th`
  min-width: 100px;
  width: 10%;
`;
