import { useCallback, useContext } from "react";
import axios from "axios";
import AuthContext from "common/store/AuthContext";
import { GetBasicResponse, PostBasicResponse, PostResponse } from "common/types/responses/BasicResponse";
import {
  genApiDatePath,
  generateErrorMsg,
  getServerUrl,
  generateSaveResult,
} from "utils/commonTools";
import { LastUpdatedInfo } from "common/types/LastUpdatedInfo";

import { ParkEventMeta } from "../types/ParkEventMeta";
import { ParkEventReport } from "../types/ParkEventData";
import { EVENT_TYPE } from "../types/EVENT_TYPE";
import { ParkEventSpecific } from "../types/ParkEventSpecific";

export const useParkEvents = () => {
  const authCtxt = useContext(AuthContext);
  const { token } = authCtxt;

  //--------------------------------------
  // 自主・協働事業のメタデータを取得する
  // 属性別参加者種別
  //--------------------------------------
  const loadMetaData = useCallback(
    async (
      parkId: number,
      eventType: EVENT_TYPE
    ): Promise<{ succeeded: boolean; msg: string; data?: ParkEventMeta }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };

      try {
        const { data } = await axios.get(getServerUrl() + `parkEvents/meta/${parkId}/${eventType}`, config);

        const metaData = data as GetBasicResponse<ParkEventMeta>;
        if (!metaData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }
        return {
          succeeded: true,
          msg: "",
          data: metaData.data,
        };
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    [token]
  );

  const getEventTypeIdPath = (eventTypeId?: number) => {
    if(eventTypeId === undefined) return ""
    return `/${eventTypeId}`;
  }

  //--------------------------------------
  // 日報データをサーバーから取得する
  //--------------------------------------
  const getReportData = useCallback(
    async (
      parkId: number,
      eventType: EVENT_TYPE,
      date: Date,
      eventTypeId?: number,
    ): Promise<{
      succeeded: boolean;
      msg: string;
      data?: ParkEventReport;
      lastUpdated?: LastUpdatedInfo;
    }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };
      try {
        // 日報データの取得
        const url =
          getServerUrl() + `parkEvents/report/${parkId}/${eventType}/${genApiDatePath(date)}${getEventTypeIdPath(eventTypeId)}`;

        const { data } = await axios.get(url, config);
        const reportData = data as PostBasicResponse<ParkEventReport>;
        if (!reportData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }

        authCtxt.updateToken(reportData.token, new Date(reportData.expiresAt));

        return {
          succeeded: true,
          msg: "",
          data: reportData.data,
          lastUpdated: reportData.lastUpdated,
        };
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    [authCtxt, token]
  );

  //--------------------------------------
  // 保存処理の実行
  //--------------------------------------
  const saveReportData = useCallback(
    async (
      parkId: number,
      eventSpecific: ParkEventSpecific,
      date: Date,
      reportData: ParkEventReport,
      title: string,
      eventTypeId?: number,
    ): Promise<{ succeeded: boolean; msg: string; lastUpdated?: LastUpdatedInfo }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };
      try {
        const url =
          getServerUrl() + `parkEvents/report/${parkId}/${eventSpecific.EventType}/${genApiDatePath(date)}${getEventTypeIdPath(eventTypeId)}`;
        const { data, status } = await axios.post(url, reportData, config);

        const responseData = data as PostResponse;
        if (!responseData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }

        authCtxt.updateToken(responseData.token, new Date(responseData.expiresAt));

        return generateSaveResult(title, status, data as PostResponse);
      } catch (error) {
        var errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    [authCtxt, token]
  );

  return { loadMetaData, getReportData, saveReportData };
};
