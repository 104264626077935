import { useContext } from "react";
import StatusContext from "common/store/StatusContext";

import { useAggregateReport } from "../hooks/useAggregateReport";
import { PrintPanelTemplate } from "./PrintPanelTemplate";
import { SelectDayRangeControl } from "./organisms/SelectDayRangeControl";

/**
 * 集計帳票の期間選択と実行
 * @returns
 */
export const PrintAggregateControl = (props: { title: string }) => {
  const { getAggregateReort } = useAggregateReport();
  const { isLoading, setIsLoading, setLoadingMessage, setErrorMessage } = useContext(StatusContext);

  const printRequested = async (startDate: Date, endDate: Date) => {
    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage(`帳票"${props.title}"を作成中...`);
    const { succeeded, msg } = await getAggregateReort(startDate, endDate, props.title);
    setIsLoading(false);

    if (!succeeded) {
      setErrorMessage(msg);
    }
  };

  return (
    <PrintPanelTemplate>
      <SelectDayRangeControl
        disabled={isLoading}
        printRequested={printRequested}
        showByMonth={false}
      />
    </PrintPanelTemplate>
  );
};
