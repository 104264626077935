import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "pages/user/hooks/useAuth";
import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";
import { BasicTemplate } from "common/components/templates/BasicTemplate";
import { LoginPanel } from "./components/LoginPanel";

const LoginPage = () => {
  const { login } = useAuth();

  const navigation = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);
  const { setErrorMessage, selectDate } = useContext(StatusContext);

  //--------------------------------------
  // ログインボタン処理
  //--------------------------------------
  const onLogin = async (loginName: string, password: string) => {
    setErrorMessage("");
    const { succeeded, msg } = await login(loginName, password);
    if (!succeeded) {
      setErrorMessage(msg);
    }

    selectDate(new Date());
    navigation("/", { replace: true });
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigation("/", { replace: true });
    }

    document.title = "InfoLand2：ログイン";
  }, [isLoggedIn, navigation]);

  return (
    <BasicTemplate>
      <LoginPanel loginRequested={onLogin} />
    </BasicTemplate>
  );
};

export default LoginPage;
