import { useContext, useState } from "react";
import styled from "styled-components";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";

import FunctionButton from "../atoms/FunctionButton";
import { CancelButton } from "../atoms/CancelButton";
import PrimaryButton from "../atoms/PrimaryButton";
import ConfirmationDlg from "../molecules/ConfirmationDlg";
import { Modal, Spinner } from "react-bootstrap";
import { REPORT_TYPE, TAB_ID } from "common/types/consts/Defines";
import { useTabEditing } from "common/hooks/useTabEditing";
import { HubConnectionState } from "@microsoft/signalr";
import NotificationContext from "common/store/NotificationContext";

type Props = {
  parkId: number;
  editKey: string;
  reportType: REPORT_TYPE;
  tabId: TAB_ID;
  onEditStart: () => Promise<void>;
  onSave: () => Promise<void>;
  onCancel: () => {};
};

let answerTimer: NodeJS.Timeout | undefined;

//=====================================
// 編集の開始、終了などをコントロールするボタンとダイアログのセット
//=====================================
export const EditControl = (props: Props) => {
  const { parkId, editKey: key, reportType, tabId, onEditStart, onSave, onCancel } = props;

  const { selectedPark, editWaitTime } = useContext(AuthContext);
  const { isEditing, setErrorMessage, editingTab, setEditingTab } = useContext(StatusContext);
  const { connection } = useContext(NotificationContext);
  const [askForceEdit, setAskForceEdit] = useState(false);
  // 強制編集開始をリクエストしている時のメッセージ
  const [showRequesting, setShowRequesting] = useState(false);
  // 強制編集したい旨のリクエストを受けた時のメッセージ
  const [requestedBy, setRequestedBy] = useState<string>();
  const [currEditor, setCurrEditor] = useState<string>();

  const { startEditing, askForStartEditing, endEditing, answerForceEditing } = useTabEditing();

  const genHandlerId = () => {
    return `${parkId}_${reportType}_${tabId}_${key}`;
  };

  /**
   * 編集開始ボタンが押された時の処理
   * @returns
   */
  const onStartEdit = async () => {
    if (!selectedPark) return;
    const res = await startEditing(selectedPark.parkId, reportType, tabId, key);
    // 通信が失敗
    if (!res.succeeded) {
      setErrorMessage(res.msg);
      return;
    }

    // 編集中のため失敗 -> 強制編集
    if (!res.data?.success) {
      setCurrEditor(res.data?.currentEditor);
      setAskForceEdit(true);
      return;
    }

    await execSetStarted();
  };

  /**
   * 編集開始した時の共通処理
   * @returns
   */
  const execSetStarted = async () => {
    if (!selectedPark) return;

    // 編集状態を記録
    setEditingTab({ parkId, reportType, tabId, key });
    await onEditStart();

    // 他の人からの編集要求を受けるため接続開始
    if (connection.state === HubConnectionState.Disconnected) await connection.start();
    const handlerId = genHandlerId();
    connection.on(handlerId, (requestBy: string) => {
      if (answerTimer) clearTimeout(answerTimer);
      // メッセージを表示
      setRequestedBy(requestBy);
      answerTimer = setTimeout(async () => {
        // 反応しなかった場合、保存して終了
        setRequestedBy(undefined);
        await onEndEdit();
        props.onSave?.();

        connection.off(handlerId);
        connection.stop();
        // setRequestedBy(undefined);
        // onCancel?.();
        setCurrEditor(undefined);
        setEditingTab(undefined);
        answerTimer = undefined;
      }, editWaitTime);
    });
  };

  /**
   * 強制編集しますか → はい → リクエスト中のメッセージを表示
   * @returns
   */
  const onForceEdit = async () => {
    if (!selectedPark) return;

    setAskForceEdit(false);
    setShowRequesting(true);

    // 強制開始の結果を受ける口を用意
    const handlerId = genHandlerId() + "_force";
    if (connection.state === HubConnectionState.Disconnected) await connection.start();

    let answered = false;
    // 明示的にOKが来た場合のハンドラ
    await connection.on(handlerId, async (ok: boolean) => {
      answered = true;
      setShowRequesting(false);
      connection.off(handlerId);

      if (ok) {
        await onStartEdit();
      } else {
        connection.stop();
        setErrorMessage("編集開始の要求が拒否されました");
      }
    });

    // 強制結果を依頼
    const res = await askForStartEditing(selectedPark.parkId, reportType, tabId, key);
    if (!answered) {
      // タイムアウトの場合
      setShowRequesting(false);
      connection.off(handlerId);

      if (res.data) {
        // タイムアウトでOKとなった場合、タイムアウトの保存のために少し待ってから開始
        setTimeout(async () => {
          await onStartEdit();
        }, 2000);
      } else {
        connection.stop();
      }
    }
  };

  /**
   * 強制開始以来に対する回答を送る
   * @param ok
   */
  const answerRequest = async (ok: boolean) => {
    if (!selectedPark) return;
    if (answerTimer) {
      clearTimeout(answerTimer);
      answerTimer = undefined;
    }
    try {
      if (ok) {
        // 先に保存する
        if (!(await onEndEdit())) return;
        await onSave?.();
      }
      // 通知
      await answerForceEditing(selectedPark.parkId, reportType, tabId, key, ok);
    } catch (err: unknown) {
      setErrorMessage("権限取得依頼への回答でエラーが発生しました");
      console.error("編集権限の返答エラー", err);
    } finally {
      setRequestedBy(undefined);
    }
  };

  /**
   * 編集の終了
   * @returns
   */
  const onEndEdit = async (): Promise<boolean> => {
    if (!selectedPark) return false;
    if (!editingTab) {
      setErrorMessage("編集中ではありません");
      return false;
    }
    const res = await endEditing(selectedPark.parkId, editingTab.reportType, editingTab.tabId, key);
    // 終了失敗しても解決できないので編集自体は終わりにする
    setCurrEditor(undefined);
    setEditingTab(undefined);

    if (!res.succeeded) {
      setErrorMessage(res.msg);
      return false;
    }
    connection.off(genHandlerId());
    await connection?.stop();
    return true;
  };

  return (
    <>
      {!isEditing ? (
        <FunctionButton onClick={onStartEdit} size="md" tooltip="編集を開始する">
          {<i className="fas fa-edit" />}
        </FunctionButton>
      ) : (
        <SButtonDiv>
          <CancelButton
            onClick={async (e) => {
              e.preventDefault();
              if (await onEndEdit()) onCancel?.();
              else
                setTimeout(() => {
                  // キャンセル失敗してもメッセージを表示してしばらくしたら戻す
                  onCancel?.();
                }, 10000);
            }}
          />
          <PrimaryButton
            title="保存"
            onClick={async () => {
              // 編集終了してないといけない
              if (!(await onEndEdit())) return;
              onSave?.();
            }}
          />
        </SButtonDiv>
      )}
      {askForceEdit && (
        <ConfirmationDlg
          show={askForceEdit}
          title="編集中"
          messages={[`${currEditor}さんが編集中です。`, `編集の開始を依頼しますか`]}
          onOk={onForceEdit}
          onCancel={() => setAskForceEdit(false)}
          okText="はい"
          cancelText="いいえ"
        />
      )}
      {showRequesting && (
        <Modal show={showRequesting} centered>
          <Modal.Body>
            <SRequesting>
              <Spinner />
              <span>問合せ中… </span>
            </SRequesting>
          </Modal.Body>
        </Modal>
      )}
      {requestedBy && (
        <ConfirmationDlg
          show={requestedBy !== undefined}
          title={"編集依頼"}
          messages={[
            `${requestedBy}さんが編集をリクエストしています。`,
            "編集権限を譲りますか？",
            "※ 編集した内容(ダイアログ入力中を除く)は保存されます。",
          ]}
          onOk={async () => await answerRequest(true)}
          onCancel={async () => await answerRequest(false)}
          okText="はい"
          cancelText="いいえ"
        />
      )}
    </>
  );
};

const SButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

const SRequesting = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;
