import React from "react";
import FunctionButton from "../atoms/FunctionButton";

type Props = {
  editRequested?: () => void;
  deleteRequested?: () => void;
  editTooltip?: string;
  deleteTooltip?: string;
};

//=====================================
// テーブルデータの修正・削除ボタン
//=====================================
export const RowEditButtons = (props: Props) => {
  const { editRequested, deleteRequested } = props;
  return (
    <>
      {editRequested && (
        <FunctionButton
          tooltip={props.editTooltip ?? "編集"}
          className="ms-1 my-1"
          size="sm"
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            editRequested();
          }}
        >
          <i className="fas fa-pen-to-square" />
        </FunctionButton>
      )}
      {deleteRequested && (
        <FunctionButton
          buttonType="delete"
          tooltip={props.deleteTooltip ?? "削除"}
          className="ms-1"
          size="sm"
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            deleteRequested();
          }}
        >
          <i className="fas fa-trash-alt" />
        </FunctionButton>
      )}
    </>
  );
};
