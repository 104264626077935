import { useContext, useState } from "react";

import StatusContext from "common/store/StatusContext";

import PrimaryButton from "common/components/atoms/PrimaryButton";
import { useMonthlyReport } from "../hooks/useMonthlyReport";
import { PrintPanelTemplate } from "./PrintPanelTemplate";
import { SelectYearMonthControl } from "./molecules/SelectYearMonthControl";

export const PrintMonthlyControl = (props: { title: string }) => {
  const [date, setDate] = useState(new Date());

  const { getMonthlyReort } = useMonthlyReport();
  const { isLoading, setIsLoading, setLoadingMessage, setErrorMessage } = useContext(StatusContext);

  const getReport = async () => {
    setErrorMessage("");

    setIsLoading(true);
    setLoadingMessage(`帳票"${props.title}"を作成中...`);
    const { succeeded, msg } = await getMonthlyReort(date, props.title);
    setIsLoading(false);

    if (!succeeded) {
      setErrorMessage(msg);
    }
  };

  return (
    <PrintPanelTemplate>
      <SelectYearMonthControl
        year={date.getFullYear()}
        month={date.getMonth() + 1}
        yearChanged={(newValue) => {
          const newDate = new Date(date);
          newDate.setFullYear(newValue);
          setDate(newDate);
        }}
        monthChanged={(newValue) => {
          const newDate = new Date(date);
          newDate.setMonth(newValue - 1);
          setDate(newDate);
        }}
      />
      <PrimaryButton title="出力" size="sm" onClick={getReport} disabled={isLoading} />
    </PrintPanelTemplate>
  );
};
