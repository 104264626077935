import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import styled from "styled-components";

import MyCheckbox from "common/components/atoms/MyCheckbox";
import { RowEditButtons } from "common/components/molecules/RowEditButtons";
import { NameID } from "common/types/NameID";

import FunctionButton from "common/components/atoms/FunctionButton";
import { StaffMasterData } from "../types/StaffMasterInfo";
import { useStaffMaintenance } from "../hooks/useStaffMaintenance";
import { EditStaffDlg } from "./EditStaffDlg";

type Props = {
  staffs: StaffMasterData[];
  posts: NameID[];
  updated: (value: StaffMasterData) => Promise<number>;
};

export const StaffListPanel = (props: Props) => {
  const { staffs, posts, updated } = props;
  const [filteredStaffs, setFilteredStaffs] = useState<StaffMasterData[]>([]);
  const { createEmptyStaff } = useStaffMaintenance();
  const [editTarget, setEditTarget] = useState<StaffMasterData>();
  const [showAll, setShowAll] = useState(false);

  const addDataRequested = () => {
    const newData = createEmptyStaff(posts);
    setEditTarget(newData);
  };

  const editDataRequested = (target: StaffMasterData) => {
    setEditTarget(target);
  };

  const editDataCompleted = async (newData: StaffMasterData) => {
    const newId = await updated(newData);
    // エラーは0以下
    if (newId < 0) return;

    if (newData.employeeID < 0) {
      // 追加
      newData.employeeID = newId;
      staffs.push(newData);
    } else {
      // 更新
      const index = staffs.findIndex((x) => x.employeeID === newData.employeeID);
      staffs[index] = newData;
    }
    setEditTarget(undefined);
    updateList();
  };

  const staffSorter = (a: StaffMasterData, b: StaffMasterData): number => {
    // スタッフ、臨時雇用者は0以下なので、大きな値に変換してソートする
    const id1 = a.postID < 0 ? -a.postID + 99999 : a.postID;
    const id2 = b.postID < 0 ? -b.postID + 99999 : b.postID;

    // 優先順位：役職 → 表示順 → 従業員ID
    if (id1 === id2) {
      if (a.displayOrder === b.displayOrder) return a.employeeID > b.employeeID ? 1 : -1;
      return a.displayOrder > b.displayOrder ? 1 : -1;
    }
    return id1 > id2 ? 1 : -1;
  };

  const updateList = () => {
    if (showAll) setFilteredStaffs(staffs.sort(staffSorter));
    else setFilteredStaffs(staffs.filter((x) => x.isWorking).sort(staffSorter));
  };

  useEffect(() => {
    updateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAll, staffs]);
  return (
    <>
      <div className="d-flex justify-content-between mx-2 my-2">
        <MyCheckbox
          initialValue={showAll}
          label="すべて表示"
          changed={(value) => setShowAll(value)}
        />
        <FunctionButton
          onClick={(e) => {
            e.preventDefault();
            addDataRequested();
          }}
          tooltip="追加"
        >
          <i className="fa-regular fa-plus"></i>
        </FunctionButton>
      </div>
      <Table bordered hover responsive>
        <thead>
          <tr>
            <th className="col-md-2 text-center">名前</th>
            <th className="col-md-1 text-center">役職</th>
            <th className="col-md-1 text-center">表示順</th>
            <th className="col-md-1 text-center">現職</th>
            <th className="col-md-1 text-center">操作</th>
          </tr>
        </thead>
        <tbody>
          {filteredStaffs.map((staff) => (
            <tr key={`staff_${staff.employeeID}`}>
              <TdLeft>{staff.employeeName}</TdLeft>
              <TdCenter className="text-center">{staff.postName}</TdCenter>
              <TdCenter className="text-center">{staff.displayOrder}</TdCenter>
              <TdCenter className="text-center">
                <MyCheckbox initialValue={staff.isWorking} readonly={true} />
              </TdCenter>
              <TdCenter style={{ padding: 0 }}>
                <RowEditButtons editRequested={() => editDataRequested(staff)} />
              </TdCenter>
            </tr>
          ))}
        </tbody>
      </Table>
      {editTarget && (
        <EditStaffDlg
          target={editTarget}
          posts={posts}
          onOK={editDataCompleted}
          onCancel={() => setEditTarget(undefined)}
        />
      )}
    </>
  );
};

const TdLeft = styled.td`
  vertical-align: middle;
  text-align: start;
`;

const TdCenter = styled.td`
  vertical-align: middle;
  text-align: center;
`;
