import { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";

import { getDateString } from "utils/commonTools";

import { ILCalendarDlg } from "common/components/organisms/ILCalendarDlg";

type Params = {
  startDate: Date;
  endDate: Date;
  startDateChanged: Dispatch<SetStateAction<Date>>;
  endDateChanged: Dispatch<SetStateAction<Date>>;
};

export const SelectStartAndEndDayControl = (params: Params) => {
  const { startDate, endDate, startDateChanged, endDateChanged } = params;
  const [showCalendarStart, setShowCalendarStart] = useState(false);
  const [showCalendarEnd, setShowCalendarEnd] = useState(false);

  const startDateSelected = (newDate: Date) => {
    startDateChanged(newDate);
    if (endDate < newDate) endDateChanged(newDate);
    setShowCalendarStart(false);
  };

  const endDateSelected = (newDate: Date) => {
    endDateChanged(newDate);
    if (startDate > newDate) startDateChanged(newDate);
    setShowCalendarEnd(false);
  };

  return (
    <>
      <SDateDiv>
        <div> 開始日付： {getDateString(startDate)}</div>
        <Button
          variant="outline-info"
          size="sm"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setShowCalendarStart(true);
          }}
        >
          <i className="fas fa-calendar-days" />
        </Button>
      </SDateDiv>
      <SDateDiv>
        <div> 終了日付： {getDateString(endDate)}</div>
        <Button
          variant="outline-info"
          size="sm"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setShowCalendarEnd(true);
          }}
        >
          <i className="fas fa-calendar-days" />
        </Button>
      </SDateDiv>
      {/* カレンダー開始 */}
      {showCalendarStart && (
        <ILCalendarDlg
          show={showCalendarStart}
          initialValue={startDate}
          onOK={startDateSelected}
          onCancel={() => setShowCalendarStart(false)}
        />
      )}
      {/* カレンダー終了 */}
      {showCalendarEnd && (
        <ILCalendarDlg
          show={showCalendarEnd}
          initialValue={endDate}
          onOK={endDateSelected}
          onCancel={() => setShowCalendarEnd(false)}
        />
      )}
    </>
  );
};

const SDateDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding-top: 0.5rem;
`;
