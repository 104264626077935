import { useEffect, useState } from "react";
import { Form, Modal, Stack } from "react-bootstrap";

import { EVENT_TYPE } from "pages/parkevents/types/EVENT_TYPE";

import { PeriodicalEvent } from "../types/PeriodicalEvent";
import { usePeriodData } from "../hooks/usePeriodData";

import PrimaryButton from "common/components/atoms/PrimaryButton";
import { MyDatePicker } from "common/components/atoms/MyDatePicker";
import { CancelButton } from "common/components/atoms/CancelButton";

type Props = {
  target: PeriodicalEvent;
  pageTitle: string;
  onOK: (value: PeriodicalEvent) => void;
  onCancel: () => void;
};

export const EditPeriodicalEventDlg = (props: Props) => {
  const { target, pageTitle } = props;
  const [data, setData] = useState<PeriodicalEvent>({ ...target });
  const { getEventTypeString } = usePeriodData();

  useEffect(() => {
    setData(target);
  }, [target]);
  return (
    <Modal show={target !== undefined}>
      <Modal.Header>{`${pageTitle}の${target.eventId < 0 ? "追加" : "編集"}`}</Modal.Header>
      <Modal.Body>
        <Stack>
          <Form.Label column md={2}>
            タイトル
          </Form.Label>
          <Form.Control
            value={data.title}
            onChange={(e) => {
              setData({ ...data, title: e.target.value });
            }}
          />
          <Form.Label column md={2}>
            種別
          </Form.Label>
          <Form.Select
            value={data.eventType}
            onChange={(e) => {
              e.preventDefault();
              setData({ ...data, eventType: +e.currentTarget.value });
            }}
          >
            {Object.entries(EVENT_TYPE).map(([, value], index) => {
              if (index < Object.entries(EVENT_TYPE).length / 2) return <></>;
              return (
                <option value={value} key={`evType_${value}`}>
                  {getEventTypeString(value as EVENT_TYPE)}
                </option>
              );
            })}
          </Form.Select>

          <Form.Label column md={2}>
            開始日付
          </Form.Label>
          <MyDatePicker
            value={data.startDate}
            onChange={(val) => setData({ ...data, startDate: val })}
          />
          <Form.Label column md={2}>
            終了日付
          </Form.Label>
          <MyDatePicker
            value={data.endDate}
            onChange={(val) => setData({ ...data, endDate: val })}
          />
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <CancelButton
          onClick={(e) => {
            e.preventDefault();
            props.onCancel();
          }}
        />
        <PrimaryButton
          className="mx-2"
          disabled={data.title.trim().length === 0}
          onClick={() => {
            props.onOK(data);
          }}
          title="決定"
        />
      </Modal.Footer>
    </Modal>
  );
};
