/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";
import { createDocumentTitle } from "utils/commonTools";

import { PageTop } from "common/components/organisms/PageTop";
import { DashboardTemplate } from "common/components/templates/DashboardTemplate";
import { BaseForm } from "common/components/templates/BaseForm";

import { LastUpdatedInfo } from "common/types/LastUpdatedInfo";

import { VisitorTable } from "./components/VisitorTable";

import { VisitorReport } from "./types/VisitorReport";

import { useVisitors } from "./hooks/useVisitors";
import { useVisitorData } from "./hooks/useVisitorData";
import { REPORT_TYPE, TAB_ID } from "common/types/consts/Defines";

let isInitializing = false;

//=====================================
// 利用者数ページ
//=====================================
const ParkVisitorsPage = (props: { pageTitle: string }) => {
  const pageTitle = props.pageTitle;
  const [lastUpdated, setLastUpdated] = useState<LastUpdatedInfo | undefined>(undefined);

  const { clearData, applyData } = useVisitorData();
  const { loadMetaData, getReportData, saveReportData } = useVisitors();

  const { selectedPark, readOnly } = useContext(AuthContext);
  const {
    currentDate,
    setIsLoading,
    setLoadingMessage,
    setErrorMessage,
    showToastMessage,
    isEditing,
  } = useContext(StatusContext);

  const [reportData, setReportData] = useState<VisitorReport | null>(null);

  //--------------------------------------
  // 初期化処理： メタデータ取得 → 日報データ取得
  //--------------------------------------
  const initialize = async (): Promise<VisitorReport | null> => {
    setErrorMessage("");
    if (!selectedPark) return null;

    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("メタデータの取得中");

    const { succeeded, msg, data } = await loadMetaData(selectedPark.parkId);
    setIsLoading(false);
    if (succeeded) {
      return data!;
    } else {
      setErrorMessage(msg);
      return null;
    }
  };

  //--------------------------------------
  // 設定されている日付の日報データをロードする
  //--------------------------------------
  const loadReportData = async (baseData: VisitorReport) => {
    if (!selectedPark) return;

    // フィールドは一旦クリア。登録済み日報が存在すれば後で更新される
    const newdata = clearData(baseData);
    if (!newdata) {
      setErrorMessage("フィールドのクリアに失敗しました");
      return;
    }

    setErrorMessage("");
    setIsLoading(true);
    setLoadingMessage("日報データの取得中");

    const { succeeded, msg, data, lastUpdated } = await getReportData(
      selectedPark.parkId,
      currentDate
    );
    setIsLoading(false);
    if (!succeeded) {
      setErrorMessage(msg || "");
    }
    setLastUpdated(
      lastUpdated ? { ...lastUpdated, savedByApp: lastUpdated?.savedByApp || false } : undefined
    );
    setReportData(applyData(data!, newdata));
  };

  //--------------------------------------
  // 更新処理の実行
  //--------------------------------------
  const onSave = async () => {
    if (!selectedPark || !reportData) return;

    setErrorMessage("");

    setIsLoading(true);
    setLoadingMessage("日報の更新中");

    const { succeeded, msg, lastUpdated } = await saveReportData(
      selectedPark.parkId,
      currentDate,
      reportData,
      pageTitle
    );

    setIsLoading(false);
    if (succeeded) {
      if (lastUpdated) {
        setLastUpdated({ ...lastUpdated, savedByApp: false });
      }
      showToastMessage("日報の登録", msg);
    } else {
      setErrorMessage(msg);
    }
  };

  //--------------------------------------
  // 初回又は日付が変わった時に実行されるコード
  //--------------------------------------
  useEffect(() => {
    document.title = createDocumentTitle(pageTitle);
    const f = async () => {
      if (isInitializing) return;

      let baseData = reportData;
      if (!baseData) {
        isInitializing = true;
        baseData = await initialize();
        isInitializing = false;
        if (!baseData) {
          return;
        }
      }
      loadReportData(baseData);
    };
    f();
  }, [currentDate]); // eslint-disable-line react-hooks/exhaustive-deps

  //--------------------------------------
  // Rendering
  //--------------------------------------
  return (
    <DashboardTemplate>
      {!selectedPark && <div> 公園が選択されていません </div>}
      {selectedPark && reportData && (
        <>
          <PageTop
            title={pageTitle}
            reportType={REPORT_TYPE.DAILY}
            tabId={TAB_ID.VISITORS}
            lastUpdated={lastUpdated}
            onEditStart={async () => await loadReportData(reportData)}
            onSave={!readOnly ? onSave : undefined}
            onCancel={async () => await loadReportData(reportData)}
          />
          <BaseForm>
            <Row>
              {reportData.places && reportData.places.length > 0 && (
                <Col xs={12} lg={reportData.places[0].counts.length + 3}>
                  <VisitorTable
                    title="来園場所別利用者数"
                    columnTitle="来園場所"
                    data={reportData.places}
                    readOnly={!isEditing}
                  />
                </Col>
              )}
              {reportData.methods && reportData.methods.length > 0 && (
                <Col xs={12} lg={reportData.methods[0].counts.length + 3}>
                  <VisitorTable
                    title="来園方法"
                    columnTitle="来園方法"
                    data={reportData.methods}
                    readOnly={!isEditing}
                  />
                </Col>
              )}
              {reportData.others && reportData.others.length > 0 && (
                <Col xs={12} lg={reportData.others[0].counts.length + 3}>
                  <VisitorTable
                    title="特記種別利用者数"
                    columnTitle="利用者種別"
                    data={reportData.others}
                    readOnly={!isEditing}
                  />
                </Col>
              )}
            </Row>
          </BaseForm>
        </>
      )}
    </DashboardTemplate>
  );
};

export default ParkVisitorsPage;
