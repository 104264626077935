export const MultiLineText = (props: { text: string }) => {
  return (
    <>
      {props.text.split("\n").map((x, idx) => (
        <span key={`mlt_${idx}`}>
          {x}
          <br />
        </span>
      ))}
    </>
  );
};
