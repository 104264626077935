import { memo } from "react";
import { Spinner } from "react-bootstrap";
import styled from 'styled-components'

const LoadingSpinner = memo((props:any) => {
	return (
		<>
			<Spinner {...props}>
				<span className="visually-hidden"> Loading ... </span>
			</Spinner>
			<Sspan>{props.message}</Sspan>
		</>
	);
});

export default LoadingSpinner;

const Sspan = styled.span`
	color: white;
`;