import { useEffect, useState } from "react";
import styled from "styled-components";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";

import { StaffsList } from "./StaffsList";
import { NameIDList } from "common/components/molecules/NameIDList";

import MyTextArea from "common/components/atoms/MyTextArae";
import { CheckListPanel } from "./CheckListPanel";

import { WorkReportMeta } from "../types/WorkReportMeta";
import { CheckListAnswer, CheckListItem, WorkReportData } from "../types/WorkReportData";
import { useWorkReportData } from "../hooks/useWorkReportData";

type Props = {
  meta: WorkReportMeta;
  data: WorkReportData;
  readonly: boolean;
  dataChanged: (data: WorkReportData) => void;
};

const ACTIVE_KEY_PATROL = "patrol";
const ACTIVE_KEY_CHECK_LIST = "checkList";

//=====================================
// 巡回レポート
//=====================================
export const PatrolReportPanel = (props: Props) => {
  const { meta, data, readonly, dataChanged } = props;
  const [report, setReport] = useState(data);
  const { createCheckLists, addAnAnswer } = useWorkReportData();
  const [activeKeyPatrol, setActiveKeyPatrol] = useState(ACTIVE_KEY_PATROL);
  const [activeKeyCheckList, setActiveKeyCheckList] = useState(ACTIVE_KEY_CHECK_LIST);

  const hasStaffs = () => {
    if (!meta) return true;
    return (
      meta.staffs.fulltime.length > 0 ||
      meta.staffs.parttime.length > 0 ||
      meta.staffs.tempstaff.length > 0
    );
  };

  useEffect(() => {
    if (meta.checkLists.length > 0 && data.checkLists.length === 0)
      data.checkLists = createCheckLists(meta.checkLists);

    setReport({ ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, meta.checkLists]);

  return (
    <>
      <Accordion activeKey={activeKeyPatrol}>
        <SHeader
          onClick={() => setActiveKeyPatrol(activeKeyPatrol === "" ? ACTIVE_KEY_PATROL : "")}
        >
          巡回報告
        </SHeader>
        <Accordion.Item eventKey={ACTIVE_KEY_PATROL}>
          <AccordionBody>
            <Row>
              <Col sm={12} md={4} className="mt-3">
                <Form.Label>巡回担当者</Form.Label>
                {hasStaffs() ? (
                  <StaffsList
                    lists={meta.staffs}
                    selecteds={report.staffs}
                    stringWhenEmpty="選択なし"
                    readonly={readonly}
                  />
                ) : (
                  <div className="h6">担当者の定義がありません</div>
                )}
              </Col>
              <Col sm={12} md={4} className="mt-3">
                <Form.Label>巡回方法</Form.Label>
                {meta.patrolMethods?.length > 0 ? (
                  <NameIDList
                    lists={meta.patrolMethods}
                    selecteds={report.patrolMethods}
                    stringWhenEmpty="選択なし"
                    readonly={readonly}
                    maxHeight="200px"
                    selectedChanged={(vals) => {
                      report.patrolMethods = vals;
                    }}
                  />
                ) : (
                  <div className="h6">巡回方法の定義がありません</div>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={4} className="mt-3">
                <Form.Label>巡回地区</Form.Label>
                {meta.areas.length > 0 ? (
                  <NameIDList
                    lists={meta.areas}
                    selecteds={report.patrolAreas}
                    stringWhenEmpty="選択なし"
                    readonly={readonly}
                    maxHeight="200px"
                    selectedChanged={(vals) => {
                      report.patrolAreas = vals;
                    }}
                  />
                ) : (
                  <div className="h6">巡回地区の定義がありません</div>
                )}
              </Col>
              <Col sm={12} md={8}>
                <Form.Label className="mt-3">巡回地区詳細</Form.Label>
                <MyTextArea
                  readonly={readonly}
                  initialValue={report.patrolAreaMemo}
                  changed={(value) => {
                    const newdata = { ...report, patrolAreaMemo: value };
                    setReport(newdata);
                    dataChanged(newdata);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mt-3 d-flex justify-content-between">
                  <Form.Label>巡回内容</Form.Label>
                  <Form.Check
                    readOnly={readonly}
                    label="異常あり"
                    type="switch"
                    checked={report.anomalyDetected}
                    onChange={(e) => {
                      if (readonly) return;
                      report.anomalyDetected = e.target.checked;
                      setReport({ ...report });
                    }}
                  />
                </div>
                <MyTextArea
                  readonly={readonly}
                  initialValue={report.patrolMemo}
                  changed={(value) => {
                    const newdata = { ...report, patrolMemo: value };
                    setReport(newdata);
                    dataChanged(newdata);
                  }}
                />
              </Col>
              <Col className="mt-3" md={4}>
                <Form.Label>注意種別</Form.Label>
                {meta.warningTypes?.length > 0 ? (
                  <NameIDList
                    lists={meta.warningTypes}
                    selecteds={report.warningTypes}
                    stringWhenEmpty="選択なし"
                    readonly={readonly}
                    maxHeight="150px"
                    selectedChanged={(vals) => {
                      report.warningTypes = vals;
                    }}
                  />
                ) : (
                  <div>注意種別の定義がありません</div>
                )}
              </Col>
            </Row>
          </AccordionBody>
        </Accordion.Item>
      </Accordion>
      {meta.checkLists.length > 0 && (
        <Accordion activeKey={activeKeyCheckList}>
          <SHeader
            onClick={() =>
              setActiveKeyCheckList(activeKeyCheckList === "" ? ACTIVE_KEY_CHECK_LIST : "")
            }
          >
            チェックリスト
          </SHeader>
          <Accordion.Item eventKey={ACTIVE_KEY_CHECK_LIST}>
            <AccordionBody>
              <CheckListPanel
                readonly={readonly}
                metaLists={meta.checkLists}
                checkList={report.checkLists}
                areas={meta.areas}
                addAnswerRequested={(item) => {
                  addAnAnswer(item);
                  setReport({ ...report });
                  dataChanged({ ...report });
                }}
                removeAnswerRequested={(answer: CheckListAnswer, item: CheckListItem) => {
                  item.results = item.results.filter((x) => x !== answer);
                  setReport({ ...report });
                  dataChanged({ ...report });
                }}
              />
            </AccordionBody>
          </Accordion.Item>
        </Accordion>
      )}
    </>
  );
};

const SHeader = styled(Accordion.Header)`
  .accordion-button {
    padding: 0.5rem;
    background-color: ${(props) => props.theme.color.menuHeader};
    color: ${(props) => props.theme.color.menuHeaderText};
    &.collapsed {
      background-color: ${(props) => props.theme.color.menuHeader};
      color: ${(props) => props.theme.color.menuHeaderText};
    }
    &::after {
      color: ${(props) => props.theme.color.menuHeaderText};
    }
  }
  border-radius: 1rem;
  box-shadow: none !important;
`;
