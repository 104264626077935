import { memo, useContext, useEffect, useState } from "react";
import { Badge, Card, Placeholder, Stack, Image } from "react-bootstrap";
import styled from "styled-components";

import { RegisteredFileInfo } from "common/types/files/RegisteredFileInfo";
import { useFiles } from "common/hooks/useFiles";
import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";
import { ShowPictureDlg } from "./ShowPictureDlg";
import FunctionButton from "common/components/atoms/FunctionButton";

type Props = {
  file: RegisteredFileInfo;
  showPicture?: boolean;
  hasComment?: boolean;
  cardWidth?: string;
  imgHeight?: string;
  canDownload?: boolean;
  editRequested?: () => void;
  deleteRequeseted?: () => void;
  readOnly: boolean;
};

// ------------------------------------------------------------------
// ファイルを表示するカード（ファイル・写真共通）
//-------------------------------------------------------------------
export const FileCard = memo((props: Props) => {
  const {
    file,
    showPicture = false,
    hasComment = false,
    cardWidth = "250px",
    imgHeight = "12rem",
    canDownload = true,
    editRequested,
    deleteRequeseted,
    readOnly,
  } = props;

  const { getPicture, downloadFile } = useFiles();
  const [picture, setPicture] = useState<string>("");
  const authCtxt = useContext(AuthContext);
  const { selectedPark } = authCtxt;
  const { setIsLoading, setLoadingMessage, setErrorMessage } = useContext(StatusContext);

  const [showPictureDlg, setShowPictureDlg] = useState(false);

  const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  //--------------------------------------
  // 写真データの取得
  //--------------------------------------
  const execLoadPicture = async () => {
    if (!selectedPark) return "";

    // アップロード前のデータ
    if (file.file) {
      const imgInString = arrayBufferToBase64(await file.file.arrayBuffer());
      setPicture(`data:image/*;base64,${imgInString}`);
      return;
    }

    // サーバーに保存済みデータ
    const result = await getPicture(selectedPark.parkId, file.fileId);
    if (result) setPicture(result.data);
  };

  //--------------------------------------
  // ファイルのダウンロード処理
  //--------------------------------------
  const execDownloadFile = async () => {
    if (!selectedPark) return;

    setIsLoading(true);
    setLoadingMessage(`${file.fileName} のダウンロード中`);

    const { succeeded, msg } = await downloadFile(selectedPark.parkId, file.fileId, file.fileName);
    setIsLoading(false);
    if (!succeeded) {
      setErrorMessage("ファイルのダウンロードに失敗しました。" + msg);
    }
  };

  useEffect(() => {
    if (showPicture) {
      const loadPict = async () => {
        await execLoadPicture();
      };
      window.setTimeout(loadPict, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card style={{ width: cardWidth }} border="primary">
        <Card.Header>
          <SHeaderDiv>
            <STitle>{file.title}</STitle>
            <Stack direction="horizontal" className="gap-1">
              {canDownload && (
                <FunctionButton
                  size="sm"
                  buttonType="primary"
                  onClick={() => execDownloadFile()}
                  tooltip="ダウンロード"
                  disabled={!!file.file}
                >
                  <i className="fas fa-file-download" />
                </FunctionButton>
              )}
              {!readOnly && (
                <>
                  <FunctionButton
                    size="sm"
                    onClick={() => editRequested?.()}
                    tooltip="タイトルの編集"
                  >
                    <i className="fas fa-pen-to-square" />
                  </FunctionButton>
                  <FunctionButton
                    size="sm"
                    buttonType="delete"
                    className="ms-2"
                    onClick={() => deleteRequeseted?.()}
                    tooltip="削除"
                  >
                    <i className="fas fa-trash-alt" />
                  </FunctionButton>
                </>
              )}
            </Stack>
          </SHeaderDiv>
        </Card.Header>
        <Card.Body style={{ minHeight: "50px", padding: "0.5rem" }}>
          {file.file && (
            <SBadge pill bg="info">
              未登録
            </SBadge>
          )}
          {showPicture &&
            (picture ? (
              <div className="d-flex justify-content-center">
                <Image
                  src={picture}
                  alt={file.fileName}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPictureDlg(true);
                  }}
                  style={{ cursor: "pointer", maxHeight: imgHeight, width: "auto" }}
                  title={file.fileName}
                  className="img-thumbnail p-0"
                />
              </div>
            ) : (
              <Placeholder className="w-100" style={{ height: imgHeight }} />
            ))}
          {hasComment && <Card.Text>{file.comment}</Card.Text>}
        </Card.Body>
      </Card>
      <ShowPictureDlg
        show={showPictureDlg}
        onClose={() => setShowPictureDlg(false)}
        image={picture}
        file={file}
      />
    </>
  );
});

const SHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const STitle = styled.div`
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SBadge = styled(Badge)`
  position: absolute;
  margin: 0;
`;
