import { memo, useState } from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";

import PrimaryButton from "common/components/atoms/PrimaryButton";
import FormContainer from "common/components/templates/FormContainer";

type Props = {
  loginRequested: (userName: string, password: string) => void;
};

export const LoginPanel = memo((props: Props) => {
  const [loginName, setLoginName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const clickHandler = () => {
    setShowPassword(!showPassword);
  };

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await onSubmit();
  };

  const onSubmit = async () => {
    props.loginRequested(loginName, password);
  };

  return (
    <FormContainer size="small">
      <div className="card shadow mb-4 mt-2 px-3 pb-4">
        <h3 className="my-4">ログイン</h3>
        <Form onSubmit={submitHandler}>
          <FloatingLabel controlId="loginName" label="ユーザー名" className="mb-3">
            <Form.Control
              type="text"
              placeholder="ユーザー名を入力してください"
              value={loginName}
              onChange={(e) => setLoginName(e.target.value)}
              autoComplete="off"
            ></Form.Control>
          </FloatingLabel>

          <InputGroup className="mb-3">
            <FloatingLabel controlId="password" label="パスワード">
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="パスワードを入力してください"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="off"
              ></Form.Control>
            </FloatingLabel>
            <div className="input-group-text">
              <i
                onClick={clickHandler}
                className={showPassword ? "fas fa-eye-slash" : "fas fa-eye"}
              />
            </div>
          </InputGroup>

          <div className="d-flex mt-4">
            <PrimaryButton title="ログイン" onClick={onSubmit} type="submit" />
          </div>
        </Form>
      </div>
    </FormContainer>
  );
});
