import React, { useContext, memo } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

import AuthContext from "common/store/AuthContext";
import StatusContext from "common/store/StatusContext";

import LoadingSpinner from "../atoms/LoadingSpinner";
import { ILLinkContainer } from "../atoms/ILLinkContainer";

export const Topbar = memo(() => {
  const navigation = useNavigate();
  const authCtx = useContext(AuthContext);
  const { isLoading, loadingMessage, isEditing } = useContext(StatusContext);

  const changeParkHandler = () => {
    authCtx.selectPark(null);
    navigation("/");
  };

  const changePasswordHandler = () => {
    navigation("/password");
  };

  const logoutHandler = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    authCtx.logout();
    navigation("/");
  };

  return (
    <header>
      <SNavbar
        className="d-flex justify-content-between px-2"
        bg="primary"
        variant="dark"
        collapseOnSelect
      >
        <SLeftPart>
          <ILLinkContainer to="/" className="navbar-brand ms-2">
            <div style={{ cursor: "pointer" }}>Information Landscape</div>
          </ILLinkContainer>
          <STitle> {authCtx.selectedPark?.parkName}</STitle>
          {isLoading && (
            <LoadingSpinner
              animation="border"
              variant="light"
              size="sm"
              className="my-0 mx-2"
              message={loadingMessage}
            />
          )}
        </SLeftPart>
        <div>
          {authCtx.isLoggedIn && (
            <Nav>
              <NavDropdown
                title={<STitle>{`こんにちは、${authCtx.userName}さん`}</STitle>}
                disabled={isEditing}
              >
                <NavDropdown.Item className="text-primary" onClick={changeParkHandler}>
                  <i className="fas fa-exchange-alt"></i> <span className="mx-2">公園変更 </span>
                </NavDropdown.Item>

                <NavDropdown.Item className="text-primary" onClick={changePasswordHandler}>
                  <i className="fas fa-key"></i> <span className="mx-2">パスワード変更 </span>
                </NavDropdown.Item>
                <NavDropdown.Item className="text-primary" onClick={logoutHandler}>
                  <i className="fas fa-sign-out-alt"></i> <span className="mx-2">ログアウト </span>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          )}
        </div>
      </SNavbar>
    </header>
  );
});

export default Topbar;

const SNavbar = styled(Navbar)`
  background-image: none !important;
  background-color: ${(props) => props.theme.color.title} !important;
  z-index: 999;
`;

const SLeftPart = styled.div`
  display: flex;
  align-items: baseline;
`;

const STitle = styled.span`
  color: white;
`;
