import { FileListCards } from "common/components/organisms/files/FileListCards";
import { FILE_ACCEPTS_IMAGES } from "common/types/consts/Defines";
import { RegisteredFileInfo } from "common/types/files/RegisteredFileInfo";
import { ForceCloseHandler } from "common/types/ForceCloseHandler";
import { forwardRef, useEffect, useState } from "react";

type Props = {
  pictures: RegisteredFileInfo[];
  readonly: boolean;
  picturesChanged: (pictures: RegisteredFileInfo[]) => void;
};

export const TaskFilesPanel = forwardRef<ForceCloseHandler, Props>((props: Props, ref) => {
  const [pictures, setPictures] = useState<RegisteredFileInfo[]>([]);

  useEffect(() => {
    setPictures(props.pictures);
  }, [props.pictures]);

  return (
    <FileListCards
      files={pictures}
      attr={{
        title: "写真",
        accepts: FILE_ACCEPTS_IMAGES,
        usePicture: true,
        hasComment: false,
        imgHeight: "8rem",
      }}
      ref={ref}
      readonly={props.readonly}
      listChanged={(files) => {
        const newPictures = files ?? [];
        setPictures(newPictures);
        props.picturesChanged(newPictures);
      }}
    />
  );
});
