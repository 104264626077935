import { ReactNode, createContext } from "react";
import * as signalR from "@microsoft/signalr";
import { getServerUrl } from "utils/commonTools";

type NotificationContextType = {
  connection: signalR.HubConnection;
};

const NotificationContext = createContext({} as NotificationContextType);

export default NotificationContext;

const connection = new signalR.HubConnectionBuilder()
  .withUrl(`${getServerUrl()}editPage`, {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets,
  })
  .build();

export const NotificationContextProvider = (props: { children: ReactNode }) => {
  const { children } = props;

  const contextValue = {
    connection,
  };

  return (
    <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>
  );
};
