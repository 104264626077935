import { memo, ChangeEvent, useEffect, useState } from "react";
import styled, { CSSProperties } from "styled-components";
import { Form } from "react-bootstrap";

type Props = {
  className?: string;
  initialValue: number;
  changed: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  readonly?: boolean;
  type?: "int" | "float";
  style?: CSSProperties;
  borderAlways?: boolean;
};

const MyNumberInput = memo((props: Props) => {
  const {
    className = "",
    initialValue,
    changed,
    min = 0,
    max = 999999,
    step = 1,
    readonly = false,
    type = "int",
    style,
    borderAlways,
  } = props;
  const [value, setValue] = useState("0");

  useEffect(() => {
    setValue(initialValue?.toString() ?? 0);
  }, [initialValue]);

  return (
    <SFormControl
      type="number"
      min={min}
      max={max}
      step={step}
      value={value}
      className={`text-end px-1 ${className} ${borderAlways && "borderAlways"}`}
      style={style}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        // ユーザビリティのための処理
        let val = e.target.value;
        if (val.length === 0) {
          // 数字を全部選んで削除
          val = "0";
        }

        const newVal = type === "int" ? parseInt(val) : parseFloat(val);
        const validatedValue = Math.min(max, Math.max(newVal, min));
        setValue(validatedValue.toString());
        changed(validatedValue);
      }}
      disabled={readonly}
    />
  );
});

export default MyNumberInput;

const SFormControl = styled(Form.Control)`
  line-height: 1.2;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  &:disabled {
    border-color: #aaaa;
    background-color: transparent;
    :not(.borderAlways) {
      border: none;
    }
  }
`;
