/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { StaffInfo } from "common/types/Staffs";
import { StaffAttendance, StaffAttendanceReport } from "../types/StaffInfo";

export const useStaffsData = () => {
  //--------------------------------------
  // メタデータから、サーバーに送受信するデータのひな型を作る
  // テーブルの1行に結びつく情報
  //--------------------------------------
  const createStaffData = useCallback((staff: StaffInfo) => {
    return {
      employeeID: staff.employeeID,
      employeeName: staff.employeeName,
      postID: staff.postID,
      postName: staff.postName,
      expected: false,
      attended: false,
      memo: "",
    };
  }, []);

  //--------------------------------------
  // 日報データに対応する従業員を表示中のリストから取り出し、更新する
  // いなければ今は働いていない人なので、一時的に追加する
  //--------------------------------------
  const updateStaffData = useCallback(
    (lstStaffs: StaffAttendance[], reported: StaffAttendance[]) => {
      reported.forEach((target) => {
        const found = lstStaffs?.find((staff) => staff.employeeID === target.employeeID);
        if (found) {
          found.expected = target.expected;
          found.attended = target.attended;
          found.memo = target.memo;
        } else {
          lstStaffs?.push({ ...target, retired: true });
        }
      });
    },
    []
  );

  //--------------------------------------
  // 日付が変わったりした時にデータの初期化を行う
  //--------------------------------------
  const clearData = useCallback((baseData: StaffAttendanceReport) => {
    // チェックを全てオフに
    baseData.fulltime?.forEach((staff) => resetStaffData(staff));
    baseData.parttime?.forEach((staff) => resetStaffData(staff));
    baseData.tempstaff?.forEach((staff) => resetStaffData(staff));

    // 退職済みの人のデータが入っている場合取り除く
    baseData.fulltime = baseData.fulltime?.filter((staff) => !staff.retired);
    baseData.parttime = baseData.parttime?.filter((staff) => !staff.retired);
    baseData.tempstaff = baseData.tempstaff?.filter((staff) => !staff.retired);

    // データがないときに未定義にする
    if (baseData.fulltime?.length === 0) {
      baseData.fulltime = undefined;
    }
    if (baseData.parttime?.length === 0) {
      baseData.parttime = undefined;
    }
    if (baseData.tempstaff?.length === 0) {
      baseData.tempstaff = undefined;
    }

    return {
      fulltime: baseData.fulltime,
      parttime: baseData.parttime,
      tempstaff: baseData.tempstaff,
      others: "",
      numothers: 0,
    };
  }, []);

  //--------------------------------------
  // 従業員情報の出勤予定・実績や備考をリセットする
  //--------------------------------------
  const resetStaffData = (staff: StaffAttendance) => {
    staff.expected = false;
    staff.attended = false;
    staff.memo = "";
  };

  //--------------------------------------
  // データAからデータBへデータを適用する
  //--------------------------------------
  const applyData = useCallback(
    (dataFrom: StaffAttendanceReport | undefined, dataTo: StaffAttendanceReport) => {
      // 取得したデータを反映
      if (!dataFrom) {
        return dataTo;
      }

      dataTo.others = dataFrom.others;
      dataTo.numothers = dataFrom.numothers;
      if (dataFrom.fulltime && dataFrom.fulltime.length > 0) {
        if (!dataTo.fulltime) {
          dataTo.fulltime = [];
        }
        updateStaffData(dataTo.fulltime, dataFrom.fulltime);
      }
      if (dataFrom.parttime && dataFrom.parttime.length > 0) {
        if (!dataTo.parttime) {
          dataTo.parttime = [];
        }
        updateStaffData(dataTo.parttime, dataFrom.parttime);
      }
      if (dataFrom.tempstaff && dataFrom.tempstaff.length > 0) {
        if (!dataTo.tempstaff) {
          dataTo.tempstaff = [];
        }
        updateStaffData(dataTo.tempstaff, dataFrom.tempstaff);
      }
      return dataTo;
    },
    []
  );

  return {
    createStaffData,
    updateStaffData,
    clearData,
    resetStaffData,
    applyData,
  };
};
