import JapaneseHolidays from "japanese-holidays";
import configData from "../config.json";
import { PostResponse } from "common/types/responses/BasicResponse";

//--------------------------------------
// エラーメッセージの生成
//--------------------------------------
export const generateErrorMsg = (error: any) => {
  switch (error?.code) {
    case "ERR_NETWORK":
      return "サーバーに接続できませんでした";
  }

  switch (error?.response?.status) {
    case 400:
      if (typeof error?.response?.data === "string") return error.response.data;
      return "リクエストのパラメタが正しくありません";
    case 401:
      if (typeof error?.response?.data === "string") return error.response.data;
      return "許可されていません";
    case 404:
      return "存在しないAPIが指定されました";
    case 500:
      return "サーバー内部でエラーが発生しました";
  }
  return error?.response?.data?.message ? error.response.data.message : error.message;
};

//--------------------------------------
// InfoLandService のURLを取得
//--------------------------------------
export const getServerUrl = () => {
  return process.env.REACT_APP_INFOLANDSERVICE2_URL || configData.SERVER_URL;
};

//--------------------------------------
// api/{year}/{month}/{day} の{year}より後ろの部分を日付から生成する
// ex. 2023/06/11
//--------------------------------------
export const genApiDatePath = (date: Date) => {
  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
};

//--------------------------------------
// api/{year}/{month} の{year}より後ろの部分を日付から生成する
// ex. 2023/06
//--------------------------------------
export const genApiMonthPath = (date: Date) => {
  return `${date.getFullYear()}/${date.getMonth() + 1}`;
};

//--------------------------------------
// apiのパラメタとして日付を渡す場合の日付を文字に変換する
// ex. 2023-06-11
//--------------------------------------
export const genApiDateParam = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

//--------------------------------------
// 各ページのタイトルを生成する
//--------------------------------------
export const createDocumentTitle = (title: string) => {
  return `InfoLand2: ${title}`;
};

const weekItems = ["日", "月", "火", "水", "木", "金", "土"];
//--------------------------------------
// 日付表示の文字列を生成
//--------------------------------------
export const getDateString = (date: Date) => {
  return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日(${
    weekItems[date.getDay()]
  })`;
};

//--------------------------------------
// 時刻表示の文字列を生成
//--------------------------------------
export const getTimeString = (date: Date) => {
  const paddedString = (val: number) => {
    return ("00" + val).slice(-2);
  };
  return (
    `${paddedString(date.getHours())}` +
    `:${paddedString(date.getMinutes())}` +
    `:${paddedString(date.getSeconds())}`
  );
};

//--------------------------------------
// 土日祝かどうかの判定
//--------------------------------------
export const getHolidayName = (date: Date) => {
  const holiday = JapaneseHolidays.isHoliday(date);
  // 祝日なら祝日名が入る
  if (holiday) return holiday;

  // 土日の判定
  switch (date.getDay()) {
    case 0:
    case 6:
      return "土日";
    default:
      return undefined;
  }
};

//--------------------------------------
// 保存処理の結果の処理の仕方は共通
//--------------------------------------
export const generateSaveResult = (
  msgTitle: string,
  status: number,
  responseData: PostResponse
) => {
  switch (status) {
    case 200:
      return {
        succeeded: true,
        msg: `${msgTitle}の情報を更新しました`,
        lastUpdated: responseData.lastUpdated,
      };
    case 201:
      return {
        succeeded: true,
        msg: `${msgTitle}の情報を登録しました`,
        lastUpdated: responseData.lastUpdated,
      };
    default:
      return { succeeded: false, msg: "不明なレスポンス:" + status };
  }
};

export const genEditKey = (date: Date) => {
  return genApiDateParam(date);
};
