import { useCallback, useContext } from "react";
import axios, { AxiosRequestConfig, HttpStatusCode } from "axios";

import AuthContext from "common/store/AuthContext";
import { BasicResponse, GetBasicResponse } from "common/types/responses/BasicResponse";
import { generateErrorMsg, getServerUrl } from "utils/commonTools";
import { NameID } from "common/types/NameID";

//--------------------------------------
// 印刷テンプレートの操作
//--------------------------------------
export const usePrintTemplate = () => {
  const authCtxt = useContext(AuthContext);
  const { token } = authCtxt;

  //--------------------------------------
  // 印刷可能なプリントの一覧を取得します
  //--------------------------------------
  const getAvailabePrintTypes = useCallback(
    async (parkId: number): Promise<{ succeeded: boolean; msg: string; data?: NameID[] }> => {
      const config = {
        headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      };

      try {
        const { data } = await axios.get(getServerUrl() + `print/meta/${parkId}`, config);
        const metaData = data as GetBasicResponse<NameID[]>;
        if (!metaData) {
          return { succeeded: false, msg: "取得したデータに異常がありました" };
        }
        return {
          succeeded: true,
          msg: "",
          data: metaData.data,
        };
      } catch (error: any) {
        const errMsg = generateErrorMsg(error);
        return { succeeded: false, msg: errMsg };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //--------------------------------------
  // ファイルのアップロード処理
  //--------------------------------------
  const uploadFile = async (
    parkId: number,
    printType: number,
    file: File
  ): Promise<{ succeeded: boolean; msg: string }> => {
    const formData = new FormData();
    formData.append("file", file!);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const { data } = await axios.post(
        getServerUrl() + `print/template/${parkId}/${printType}`,
        formData,
        config
      );
      const registered = data as BasicResponse;
      if (!registered) {
        return { succeeded: false, msg: "取得したデータに異常がありました" };
      }

      return { succeeded: true, msg: "" };
    } catch (error) {
      var errMsg = generateErrorMsg(error);
      return { succeeded: false, msg: errMsg };
    }
  };

  //--------------------------------------
  // ファイルのダウンロード処理
  //--------------------------------------
  const downloadFile = async (
    parkId: number,
    parkName: string,
    printType: number,
    printName: string
  ): Promise<{ succeeded: boolean; msg: string }> => {
    const config = {
      headers: { "Content-type": "application/json", Authorization: `Bearer ${token}` },
      responseType: "blob",
    } as AxiosRequestConfig;

    try {
      const response = await axios.get(
        getServerUrl() + `print/template/${parkId}/${printType}`,
        config
      );
      switch (response.status) {
        case HttpStatusCode.NoContent:
          return { succeeded: true, msg: "帳票は登録されていません" };
        case HttpStatusCode.Ok:
          break;
        default:
          return { succeeded: false, msg: "帳票の取得でエラーが発生しました" };
      }
      const url = window.URL.createObjectURL(response.data);

      const fileName = `${printName}_${parkName}.xlsx`;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
      return { succeeded: true, msg: "" };
    } catch (error) {
      var errMsg = generateErrorMsg(error);
      return { succeeded: false, msg: errMsg };
    }
  };

  return { getAvailabePrintTypes, uploadFile, downloadFile };
};
