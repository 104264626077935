import { useContext, useEffect, useRef, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import styled from "styled-components";

import PrimaryButton from "common/components/atoms/PrimaryButton";
import FunctionButton from "common/components/atoms/FunctionButton";

import { NameID } from "common/types/NameID";
import AuthContext from "common/store/AuthContext";

import { ChartInfo } from "../types/ChartInfo";
import { ChartTypes } from "../types/ChartTypes";
import { useChartData } from "../hooks/useChartData";
import { SelectGraphRow } from "./SelectGraphRow";
import { CancelButton } from "common/components/atoms/CancelButton";

type Props = {
  candidates: NameID[];
  charts: ChartInfo[];
  onOK: (newLists: ChartInfo[]) => void;
  onCancel: () => void;
};

export const SelectGraphsDlg = (props: Props) => {
  const [charts, setCharts] = useState<ChartInfo[]>([]);
  const { getChartTitle } = useChartData();
  const { selectedPark } = useContext(AuthContext);

  const bodyRef = useRef<HTMLDivElement>();

  const addGraph = () => {
    setCharts([...charts, { chartId: -1, numCols: 1, numRows: 1 }]);
    if (bodyRef.current) bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
  };

  useEffect(() => {
    if (props.charts.length === 0) addGraph();
    else setCharts(props.charts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.charts]);

  return (
    <Modal show={true} size="lg">
      <Modal.Header>グラフ・表の選択</Modal.Header>
      <SModalBody ref={bodyRef}>
        <Table>
          <thead>
            <tr className="text-center">
              <th className="col-md-auto">グラフ・表の種類</th>
              <th className="col-md-2">横 </th>
              <th className="col-md-2">縦</th>
              <th className="col-md-2">操作</th>
            </tr>
          </thead>
          <tbody>
            {charts.map((chart, index) => (
              <SelectGraphRow
                key={`chart_${chart.chartId}_${index}`}
                chart={{
                  chartId: chart.chartId,
                  numCols: chart.numCols,
                  numRows: chart.numRows,
                }}
                // 選択一覧 (タイトルを公園の設定に合せる)
                candidates={ChartTypes.map<NameID>((x) => ({
                  id: x.id,
                  name: getChartTitle(x.id, selectedPark?.dailyTabs) ?? x.name,
                }))}
                updated={(newChart) => {
                  charts[index] = newChart;
                  setCharts([...charts]);
                }}
                deleteRequested={() => {
                  setCharts(charts.filter((x) => x.chartId !== chart.chartId));
                }}
                canMoveUp={index > 0}
                canMoveDown={index <= charts.length - 2}
                moveUp={() => {
                  [charts[index], charts[index - 1]] = [charts[index - 1], charts[index]];
                  setCharts([...charts]);
                }}
                moveDown={() => {
                  [charts[index], charts[index + 1]] = [charts[index + 1], charts[index]];
                  setCharts([...charts]);
                }}
              />
            ))}
          </tbody>
        </Table>
      </SModalBody>
      <Modal.Footer>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <FunctionButton
            onClick={(e) => {
              e.preventDefault();
              addGraph();
            }}
            className="ms-2"
            tooltip="追加"
          >
            <i className="fa-regular fa-plus"></i>
          </FunctionButton>
          <div>
            <CancelButton
              onClick={(e) => {
                e.preventDefault();
                props.onCancel();
              }}
            />
            <PrimaryButton
              className="mx-2"
              disabled={charts.find((x) => x.chartId < 0) !== undefined}
              onClick={() => {
                props.onOK(charts);
              }}
              title="決定"
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const SModalBody = styled(Modal.Body)`
  max-height: 400px;
  overflow: auto;
`;
