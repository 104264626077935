import { Dispatch, ReactNode, SetStateAction, createContext, useState } from "react";

type MenuContextType = {
  activeKeyCommon: string;
  setActiveKeyCommon: Dispatch<SetStateAction<string>>;
  activeKeyDaily: string;
  setActiveKeyDaily: Dispatch<SetStateAction<string>>;
  activeKeyWork: string;
  setActiveKeyWork: Dispatch<SetStateAction<string>>;
  activeKeyOthers: string;
  setActiveKeyOthers: Dispatch<SetStateAction<string>>;
  activeKeySettings: string;
  setActiveKeySettings: Dispatch<SetStateAction<string>>;
};

const MenuContext = createContext({} as MenuContextType);

export default MenuContext;

export const MenuContextProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const [activeKeyCommon, setActiveKeyCommon] = useState<string>("common");
  const [activeKeyDaily, setActiveKeyDaily] = useState<string>("daily");
  const [activeKeyWork, setActiveKeyWork] = useState<string>("work");
  const [activeKeyOthers, setActiveKeyOthers] = useState<string>("others");
  const [activeKeySettings, setActiveKeySettings] = useState<string>("settings");

  const contextValue = {
    activeKeyCommon,
    setActiveKeyCommon,
    activeKeyDaily,
    setActiveKeyDaily,
    activeKeyWork,
    setActiveKeyWork,
    activeKeyOthers,
    setActiveKeyOthers,
    activeKeySettings,
    setActiveKeySettings,
  };

  return <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>;
};
