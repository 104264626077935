import { memo, ReactNode } from "react";
import { Container, Row, Col } from "react-bootstrap";

type Props = {
  children: ReactNode;
  size?: "small" | "medium" | "max";
  className?: string;
};

const FormContainer = memo((props: Props) => {
  const { children, size } = props;
  let sizes: number[] = []; // sm, md, lg, xl;
  switch (size) {
    case "small":
      sizes = [12, 8, 6, 4];
      break;
    case "medium":
      sizes = [12, 10, 8, 8];
      break;
    case "max":
      sizes = [12, 12, 12, 12];
      break;
    default:
      sizes = [12, 10, 10, 10];
      break;
  }

  return (
    <Container fluid className={`px-1 ${props.className}`}>
      <Row className="">
        <Col xs={sizes[0]} md={sizes[1]} lg={sizes[2]} xl={sizes[3]} className="mx-auto">
          {children}
        </Col>
      </Row>
    </Container>
  );
});

export default FormContainer;
